import React, { useEffect, useState } from 'react'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listDesignRoom } from '../../Redux/Design/action'
import { listCategoryRooms } from '../../Redux/Home/action';

const Room = (props) => {

  const [data, setData] = useState([]);
  const { selectedRoom, setSelectedRoom, setActive, packageData, setShow,setIsPremium } = props;
  useEffect(() => {
    localStorage.setItem('on_page','room')
    listCategoryRooms()((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.room_subscription == 1) {
      setSelectedRoom(info)
      localStorage.setItem('selectedRoom',JSON.stringify(info))
    }
    else {
      if (packageData?.package_available) {
        setSelectedRoom(info)
        localStorage.setItem('selectedRoom',JSON.stringify(info))
        setIsPremium(true)
      }
      else {
        setShow(true)
      }
    }

  }

  return (
    <div className='site-wrapper'>
      <SelectHead title="Select Room" byNo="1" link="" />
      <div className='cards-four-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedRoom?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            <div className="select-type">
              {info.room_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
            </div>
            <img src={info.room_image} alt={info.room_title} className="select-image" />
            <h3 className="select-title">{info.room_title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        <div>Your selections:</div>
        <div><button onClick={() => setActive('style')} className='button'>Next</button></div>
      </div>
    </div>
  )
}

export default Room