import React, { Fragment, useEffect, useState } from 'react'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import Slider from "react-slick";
import { getTypologyCategories, listCategoryFurniture } from '../../Redux/Design/action';

const Furniture = (props) => {

  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const { setActive, selectedStyle, selectedRoom, selectedFeel, selectedInteriorStyle, selectedColorPreference, selectedColor, selectedMaterial, selectedRoomLayout, selectedFurniture, setSelectedFurniture, packageData, setShow } = props;
  useEffect(() => {
    listCategoryFurniture()((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const handleSelection = (info) => {
    var flag = 0;
    let selected = selectedFurniture;
    if (selected && selected[0]) {
      selected?.map((data, index) => {
        if (data.id == info.id) {
          flag = 1;
          selected.splice(index, 1)
        }
      })
    }
    else {
      selected = [];
    }
    if (flag == 0) {
      selected.push(info);
    }
    console.log(selected);
    setSelectedFurniture(selected);
    setUpdate(!update)
  }
  const selectData = (info) => {
    if (info?.furniture_subscription == 1) {
      handleSelection(info)
    }
    else {
      if (packageData?.package_available) {
        handleSelection(info)
      }
      else {
        setShow(true)
      }
    }

  }
  return (
    <Fragment>
      <div className='site-wrapper'>
        <SelectHead title="Select the furniture you love ..." byNo="9" active='room_layout' setActive={setActive} />
        <div className='cards-three-list'>
          {data?.map((info, index) =>
            <button
              key={index}
              className={selectedFurniture.find(furniture => furniture.id == info.id) ? "select-card-active" : "select-card"}
              id={info.id}
              onClick={() => selectData(info)}
            >
              <div className="select-type-active">
                <span>✓</span>
              </div>
              <div className="select-type">
                {info.furniture_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
              </div>
              <img src={info.furniture_image} alt={info.furniture_title} className="select-image" />
              <h3 className="select-title">{info.furniture_title}</h3>
            </button>
          )}
        </div>
        <div className='breadcrumb'>
          <div>Your selections: <p>{selectedRoom?.room_title}, {selectedStyle?.style_title},{selectedFeel?.feels_title},{selectedInteriorStyle?.interior_title},{selectedColorPreference?.colour_preference_title},{selectedColor?.colour_title},{selectedMaterial?.material_title},{selectedRoomLayout?.room_layout_title}</p></div>
          <div><button disabled={false} onClick={() => setActive('room_shape')} className='button'>Next</button></div>
        </div>


      </div>


    </Fragment>
  )
}

export default Furniture 