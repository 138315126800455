import { GET_NOTIFICATION_DELETE, GET_NOTIFICATION_LIST, MARK_ALL_AS_READ_NOTIFICATION_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getNotificationList = (limit) => onResponse => {
    if (!limit)
        limit = '';
    try {
        axiosInstance.get(GET_NOTIFICATION_LIST + '?limit=' + limit)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllNotificationList = (limit) => onResponse => {
    if (!limit)
        limit = '';
    try {
        axiosInstance.get(GET_NOTIFICATION_LIST + '?all_notifications=true')
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteNotification = (id) => onResponse => {
    try {
        axiosInstance.delete(GET_NOTIFICATION_DELETE, { data: { id: id } })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const markAllAsReadNotification = () => onResponse => {
    try {
        axiosInstance.post(MARK_ALL_AS_READ_NOTIFICATION_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};