import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faAngleDown, faXmark, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import LoginAndSignup from './login-and-signup';
import { Link } from 'react-router-dom'
import Notification from "./notification";

const Header = ({ loggedHeader, openLogin, setOpenLogin }) => {


  // For logged interface set log logged = "logged"
  // const logged = ""
  const logged = localStorage.getItem('is_logged')
  const user = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate();

  // Mobile menu icon states
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);

  //Get started login

  useEffect(() => {
    if (openLogin) {
      setIsOpen(true)
      setOpenLogin(false)
    }
  }, [openLogin])


  // Login states
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  // Menu states
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const toggleMeunPopup = () => {
    setIsOpenMenu(!isOpenMenu);
  }

  let menuRef = useRef()
  useEffect(() => {
    let handler = (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        setIsOpenMenu(false);
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [expanded, setExpanded] = useState(false)

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY >= 60);
    });
  }, []);
  const logoutCustomer = () => {
    localStorage.clear();
    navigate('/')
  }
  const navCointainer = (
    <nav className="header">
      <div className="nav-container">
        <NavLink exact to="/" className="nav-logo">
          <img src={require("../../assets/images/stikcon-logo.png")} alt='logo' style={{ width: "150px", height: "40px" }} />
        </NavLink>
        <ul className={click ? "nav-menu active" : "nav-menu"} style={{zIndex: 999999999999}}>

          {/* ----- Mobile responsive menu ------ */}
          <div className="mobile-res-menu">
            <h3>Menu</h3>
            <FontAwesomeIcon icon={faXmark} onClick={() => setClick(!click)} />
          </div>

          {/* ----- If logged ------ */}
          {logged && (
            <div className="profile-accordion">
              <div className="profile-accordion-title">
                <div onClick={() => setExpanded(!expanded)} style={{ display: "flex", gap: "10px" }}>
                  <img src={user?.image ? user?.image : require("../../assets/images/t1.png")} alt='Profile'
                    style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                  />
                  <h3>Profile</h3>
                </div>
                <button className='faq-btn' onClick={() => setExpanded(!expanded)}>
                  {expanded ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                </button>
              </div>
              {expanded &&
                <div className="profile-accordion-links">
                  <Link to="/my-favourites">My Favourites</Link>
                  <Link to="/profile">Profile</Link>
                  <Link to="/my-designs">My Designs</Link>
                  {/* <Link to="/purchased-items">Purchased Items</Link> */}
                  <Link to="/payments">Payments</Link>
                  <div className="log_out" onClick={() => logoutCustomer()}>Logout</div>
                </div>
              }
            </div>
          )}

          {/* ----- If not logged ------ */}
          {/* ----- Starts Site Nav links ------ */}
          <li>
            <NavLink exact to="/">Home</NavLink>
          </li>
          <li>
            <NavLink exact to="/products">Product</NavLink>
          </li>
          <li>
            <NavLink exact to="/how-it-works">How it works</NavLink>
          </li>
          <li>
            <NavLink exact to="/our-designers">Our Designers</NavLink>
          </li>
          <li>
            <NavLink exact to="/faq">FAQ</NavLink>
          </li>
          <li>
            <NavLink exact to="/contact">Contact Us</NavLink>
          </li>
          {/* ----- Ends Site Nav links ------ */}

          {/* ----- Starts Logged Profile (or) Login button ------ */}
          {logged === "logged" ? (
            <li className="logged-profile-res">
              <Notification />
              <img src={user?.image ? user?.image : require("../../assets/images/t1.png")} alt='Profile'
                className="profile-img"
              />
              <FontAwesomeIcon icon={faAngleDown} onClick={toggleMeunPopup} style={{ cursor: "pointer", fontSize: "20px" }} />
              {isOpenMenu &&
                <div className="menu-popup" ref={menuRef}>
                  <div className="menu-popup-links">
                    <Link to="/my-favourites">My Favourites</Link>
                    <Link to="/profile">Profile</Link>
                    <Link to="/my-designs">My Designs</Link>
                    {/* <Link to="/purchased-items">Purchased Items</Link> */}
                    <Link to="/payments">Payments</Link>
                    <div className="log_out" onClick={() => logoutCustomer()}>Logout</div>
                  </div>
                </div>
              }
            </li>
          ) : (
            <>
              <input
                type="button"
                value="Login"
                className="button logged-btn"
                onClick={togglePopup}
              />
              {isOpen && <LoginAndSignup handleClose={togglePopup} />}
            </>
          )}
          {/* ----- Ends Logged Profile (or) Login button ------ */}
        </ul>

        {/* ----- Starts Mobile view icons  ------ */}
        <div style={{ display: "flex", alignItems: "center", gap: "20px", marginRight: "10px" }}>
          <div className="nav-icon-bell">
            <Notification />
          </div>
          <div className="nav-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={faGripLines} />
          </div>
        </div>
        {/* ----- Ends Mobile view icons  ------ */}
      </div>

    </nav>
  )

  return (
    <header>
      {loggedHeader === "false" ?
        (<nav className="header">
          {navCointainer}
        </nav>)
        : (
          (<nav className={scroll ? "index-header fixed" : "index-header"}>
            {navCointainer}
          </nav>)
        )
      }
    </header>
  )
}

export default Header