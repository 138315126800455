import React, { useEffect, useRef, useState } from 'react'
import SelectHead from '../../components/select-options/select-head';
import { listCategoryStyles } from '../../Redux/Design/action';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";

const Style = (props) => {

  const [selections, setSelections] = useState(false)
  const [data, setData] = useState([]);
  const { selectedStyle, setSelectedStyle, selectedRoom, setActive, packageData, setShow, setIsPremium } = props;
  const selectionRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('on_page','style')
    listCategoryStyles()((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.style_subscription == 1) {
      setSelectedStyle(info)
      localStorage.setItem('selectedStyle',JSON.stringify(info))
    }
    else {
      if (packageData?.package_available) {
        setSelectedStyle(info)
        localStorage.setItem('selectedStyle',JSON.stringify(info))
        setIsPremium(true)
      }
      else {
        setShow(true)
      }
    }

  }
  return (
    <div className='site-wrapper'>
      <SelectHead title="Style" byNo="2" active='room' setActive={setActive} />
      <div className='cards-four-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedStyle?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            <div className="select-type">
              {info.style_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
            </div>
            <img src={info.style_image} alt={info.style_title} className="select-image" />
            <h3 className="select-title">{info.style_title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        <div onClick={() => setSelections(true)}>Your selections <FontAwesomeIcon className="ms-1" icon={faChevronUp} /></div>


        {selections &&
          <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
            <div className="d-flex flex-row justify-content-end pe-4 pt-2">
              <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
              <div className="selection-badge">
                {selectedRoom?.room_title}
              </div>

            </div>
          </div>
        }

        <div><button disabled={false} onClick={() => setActive('feel')} className='button'>Next</button></div>
      </div>
    </div>
  )
}

export default Style