import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from '../components/layout';
// import ProductCard from '../components/product-card';
import { listCustomerDesigns } from '../Redux/Design/action';

const MyDesigns = () => {
  const [designs, setDesigns] = useState([])
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    listCustomerDesigns()((response) => {
      if (response && response.status) {
        setDesigns(response?.data?.results)
      }
    });
  }, [])


  const onViewDesignDetails = (design) => {
    setData(design)
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const downloadDesign = (image,name) => {
    image = image.replace('http://', 'https://');
     console.log(image);
    fetch(image)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  }
  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='my-designs-title'>My Designs</h3>
        <div className='my-designs-cards'>
          {designs?.map((design, index) =>
            <div className="product-card" key={index}>
              <img src={design?.custom_image} alt='design' />
              <div>
                <h3>{design?.interior?.title}</h3>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <button className='button' onClick={() => onViewDesignDetails(design)}>View Details</button>
                  <div className='mt-2' onClick={() => downloadDesign(design?.custom_image,design?.interior?.title)}> <FontAwesomeIcon icon={faDownload} /></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {show &&
        <div className="popup-box">
          <div className="design-box">
            <div className="register-info">
              <div>
                <h3>Selections</h3>
                <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
              </div>
            </div>
            <div className='d-flex flex-row justify-content-center'>
              <table>
                <tr>
                  <th>Category</th>
                  <th>Selections</th>
                </tr>
                <tr><td>Room</td><td>{data?.interior?.select_room_type?.room_title}</td></tr>
                <tr><td>Styles</td><td>{data?.interior?.select_style_type?.style_title}</td></tr>
                <tr><td>Feels</td><td>{data?.interior?.select_feels_type?.feels_title}</td></tr>
                <tr><td>Interior stylings</td><td>{data?.interior?.select_interiorstyling?.interior_title}</td></tr>
                <tr><td>Colour preferences</td><td>{data?.interior?.select_colour_preference_type?.colour_preference_title}</td></tr>
                <tr><td>Colours</td><td>{data?.interior?.select_color?.colour_title}</td></tr>
                <tr><td>Material combinations</td><td>{data?.interior?.select_material?.material_title}</td></tr>
                {/* <tr><td>Furnitures</td><td>{data?.interior?.furnitures}</td></tr> */}
                <tr><td>Room shapes</td><td>{data?.interior?.select_roomshapes?.room_shape_title}</td></tr>
                <tr><td>Room Layouts</td><td>{data?.interior?.select_roomlayout?.room_layout_title}</td></tr>
              </table>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default MyDesigns