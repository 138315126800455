import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from '../components/layout';
// import ProductCard from '../components/product-card';
import { getCustomerWishList } from '../Redux/Design/action';

const MyFavourites = () => {
  const [favourites, setFavourites] = useState([]);
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})

  const myFavourites = favourites.map((favorite, index) => {
    if (favorite?.product && favorite.product != '') {
      return (
        <div className="product-card" key={index}>
          <img src={favorite?.details[0].product_image[0]?.image} alt={favorite?.title} />
          <div>
            <h3>{favorite?.details[0].title}</h3>
            <button className='button'><Link to={'/product-details/' + favorite?.details[0].id}>View Details</Link></button>
          </div>
        </div>
      )
    }
    else if (favorite?.my_design && favorite.my_design != '') {
      return (
        <div className="product-card" key={index}>
          <img src={favorite?.details && favorite?.details[0] && favorite?.details[0]?.interior_image && favorite?.details[0]?.interior_image} alt={favorite?.details && favorite?.details[0] && favorite?.details[0]?.title} />
          <div>
            <h3>{favorite?.details && favorite?.details[0] && favorite?.details[0]?.title}</h3>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                  <button className='button' onClick={() => onViewDesignDetails(favorite)}>View Details</button>
                  <div className='mt-2' onClick={() => downloadDesign(favorite?.details[0]?.interior_image,favorite?.details[0]?.title)}> <FontAwesomeIcon icon={faDownload} /></div>
                </div>
          </div>
        </div>
      )
    }
    else if (favorite?.interior_design && favorite.interior_design != '') {
      return (
        <div className="product-card" key={index}>
          <img src={favorite?.image} alt={favorite?.title} />
          <div>
            <h3>{favorite?.title}</h3>
            <button className='button'><Link to={'/'}>View Details</Link></button>
          </div>
        </div>
      )
    }
  })
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    getCustomerWishList(user?.id)((response) => {
      if (response && response.status) {
        setFavourites(response?.data?.results)
      }
    });
  }, [])
  const onViewDesignDetails = (design) => {
    setData(design)
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const downloadDesign = (image,name) => {
    image =  image.replace('http://', 'https://');
     console.log(image);
    fetch(image)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  }
  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='my-designs-title'>My Favourites</h3>
        <div className='my-designs-cards'>
          {myFavourites}
        </div>
      </div>
    </Layout>
  )
}

export default MyFavourites