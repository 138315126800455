import React, { useEffect, useState } from 'react'
import SelectCard from '../../components/select-options/select-card'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listCategoryRoomShape } from '../../Redux/Design/action'

const EmptyRoom = (props) => {

  const [data, setData] = useState([]);
  const { setActive, selectedStyle, selectedRoom, selectedFeel, selectedInteriorStyle, selectedColorPreference, selectedColor, selectedMaterial, selectedRoomLayout, selectedFurniture, selectedRoomShape, setSelectedRoomShape, packageData, setShow } = props;
  useEffect(() => {
    
    listCategoryRoomShape(selectedRoom?.id, selectedStyle?.id, selectedFeel?.id, selectedInteriorStyle?.id, selectedColorPreference?.id, selectedColor?.id, selectedMaterial?.id, selectedRoomLayout?.id)((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.room_shape_subscription == 1) {
      setSelectedRoomShape(info)
    }
    else {
      if (packageData?.package_available) {
        setSelectedRoomShape(info)
      }
      else {
        setShow(true)
      }
    }

  }
  return (
    <div className='site-wrapper'>
      <SelectHead title="Select the room shape you love ..." byNo="10" active='furniture' setActive={setActive} />
      <div className='cards-three-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedRoomShape?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            <div className="select-type">
              {info.room_shape_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
            </div>
            <img src={info.room_shape_image} alt={info.room_shape_title} className="select-image" />
            <h3 className="select-title">{info.room_shape_title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        <div>Your selections: <p>
          {selectedRoom?.room_title},
          {selectedStyle?.style_title},
          {selectedFeel?.feels_title},
          {selectedInteriorStyle?.interior_title},
          {selectedColorPreference?.colour_preference_title},
          {selectedColor?.colour_title},
          {selectedMaterial?.material_title},
          {selectedRoomLayout?.room_layout_title},
          {selectedFurniture?.map(furniture =>
            furniture?.furniture_title ? furniture?.furniture_title + ',' : ''
          )}
        </p></div>
        <div><button disabled={false} onClick={() => setActive('typology')} className='button'>Next</button></div>
      </div>
    </div>

  )
}

export default EmptyRoom 