import React, { useState } from 'react'
import InteriorDesign from "../components/index/interior-designs"
import Testimonials from "../components/index/testimonials"
import Hero from '../components/index/hero'
import Service from '../components/index/service'
import Header from '../components/header/header'
import Footer from '../components/footer';
import Posts from '../components/index/posts'

const Index = () => {
  const [openLogin,setOpenLogin] = useState(false)
  return (
    <div>
      <Header loggedHeader="true" setOpenLogin={setOpenLogin} openLogin={openLogin} />
      <Hero setOpenLogin={setOpenLogin} openLogin={openLogin} />
      <Service />
      <Posts/>
      <Testimonials />
      <InteriorDesign />
      <Footer/>
    </div>
  )
}

export default Index