import React, { useEffect, useRef, useState } from 'react'
import SelectCard from '../../components/select-options/select-card'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listCategoryMaterial } from '../../Redux/Design/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons'

const Materials = (props) => {

  const [data, setData] = useState([]);
  const { setActive, selectedStyle, selectedRoom, selectedFeel, selectedInteriorStyle, selectedColorPreference, selectedColor, selectedMaterial, setSelectedMaterial, packageData, setShow,setIsPremium } = props;
  const selectionRef = useRef(null);
  const [selections, setSelections] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('on_page','material')
    listCategoryMaterial(selectedStyle?.id, selectedFeel?.id)((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.material_subscription == 1) {
      setSelectedMaterial(info)
      localStorage.setItem('selectedMaterial',JSON.stringify(info))
    }
    else {
      if (packageData?.package_available) {
        setSelectedMaterial(info)
        localStorage.setItem('selectedMaterial',JSON.stringify(info))
        setIsPremium(true)
      }
      else {
        setShow(true)
      }
    }

  }
  return (
    <div className='site-wrapper'>
      <SelectHead title="Materials Combination" byNo="7" active='color' setActive={setActive} />
      <div className='cards-four-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedMaterial?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            <div className="select-type">
              {info.material_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
            </div>
            <img src={info.material_image} alt={info.material_title} className="select-image" />
            <h3 className="select-title">{info.material_title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        {/* <div>Your selections: <p>{selectedRoom?.room_title}, {selectedStyle?.style_title},{selectedFeel?.feels_title},{selectedInteriorStyle?.interior_title},{selectedColorPreference?.colour_preference_title},{selectedColor?.colour_title}</p></div> */}
        <div onClick={() => setSelections(true)}>Your selections <FontAwesomeIcon className="ms-1" icon={faChevronUp} /></div>
        {selections &&
          <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
            <div className="d-flex flex-row justify-content-end pe-4 pt-2">
              <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
              <div className="selection-badge">
                {selectedRoom?.room_title}
              </div>
              <div className="selection-badge">
                {selectedStyle?.style_title}
              </div>
              <div className="selection-badge">
                {selectedFeel?.feels_title}
              </div>
              <div className="selection-badge">
                {selectedInteriorStyle?.interior_title}
              </div>
              <div className="selection-badge">
                {selectedColorPreference?.colour_preference_title}
              </div>
              <div className="selection-badge">
                {selectedColor?.colour_title}
              </div>

            </div>
          </div>
        }
        <div><button disabled={false} onClick={() => setActive('room_layout')} className='button'>Next</button></div>
      </div>
    </div>
  )
}

export default Materials