import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Layout from '../components/layout'
import { getProductsListing } from '../Redux/Product/action';
import InfiniteScroll from 'react-infinite-scroll-component';

const Products = () => {

    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const [page, setPage] = useState(0);

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = () => {
        getProductsListing(1, 15)((response) => {
            if (response && response.status) {
                setProducts(response?.data?.results)
                setPage(1)
                if (response?.data?.results.length >= response?.data?.count) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true)
                }
            }
        });
    }

    const paginate = () => {
        getProductsListing(page + 1, 15)((response) => {
            if (response.status) {
                if ((products.length + response?.data?.results.length) >= response?.data?.count) {
                    setHasMore(false)
                }
                setProducts(products.concat(response?.data?.results))
            }
            else {
                setHasMore(false)
            }
        })
        setPage(page + 1)
    }

    return (
        <Layout>
            <div className='site-wrapper'>
                <h3 className='my-designs-title'>Products</h3>
                <InfiniteScroll
                    dataLength={products.length}
                    next={paginate}
                    hasMore={hasMore}
                    loader={<p style={{ textAlign: 'center' }}>
                        <b>Loading...</b>
                    </p>}
                    endMessage={
                        products?.length == 0 ?
                            <p className='mt-5' style={{ textAlign: 'center' }}>
                                <b>No data found</b>
                            </p>
                            :
                            <p className='mt-5' style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                    }
                >
                    <div className='my-designs-cards'>

                        {products?.map((product, index) =>
                            <div className="product-card" key={index}>
                                <img src={product?.product_image[0]?.image && product?.product_image[0]?.image} alt={product?.title} />
                                <div>
                                    <h6 className='mt-2 text-muted'>{product?.name}</h6>
                                    <h5 className='mt-2'>{product?.title}</h5>
                                    <h3 className='mt-3'>{product?.rate}</h3>
                                    <button className='button'><Link to={'/product-details/' + product?.id}>View Details</Link></button>
                                </div>
                            </div>
                        )}

                    </div>
                </InfiniteScroll>

            </div>
        </Layout>
    )
}

export default Products