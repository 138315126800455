import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Home from "./pages/index"
import Contact from "./pages/contact"
import Faq from "./pages/faq"
import AboutUs from './pages/about-us';
import Help from './pages/help';
import Profile from './pages/profile';
import Payments from './pages/payments';
import MyDesigns from './pages/my-designs';
import MyFavourites from './pages/my-favourites';

import Room from './pages/select-options/room';
import Style from './pages/select-options/style';
import Feel from './pages/select-options/feel';
import Styleing from './pages/select-options/styleing';
import ColourPreference from './pages/select-options/colour-preference';
import Colour from './pages/select-options/colour';
import Materials from './pages/select-options/materials';
import Furniture from './pages/select-options/furniture';
import RoomLayout from './pages/select-options/room-layout';
import EmptyRoom from './pages/select-options/empty-room';

import OurDesigners from "./pages/our-designers"
import DesignerProfile from './pages/designer-profile';
import Pricing from './pages/pricing';
import LoadingPage from './pages/loading-page';
import ProductDetailsPage from './pages/product-details-page';
import YourDesign from './pages/your-design';
import HowItWorks from './pages/how-it-works';
import PurchasedItems from './pages/purchased-items';
import Design from './pages/design';
import TopDesigns from './pages/top-designs';
import Products from './pages/products';
import RazorpayIntegration from './components/RazorpayIntegration';
import Viewallnotification from './pages/viewallnotification';
import ErrorPage from './components/404-Page/404-Page';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/help' element={<Help />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/my-favourites' element={<MyFavourites />} />
          <Route path='/my-designs' element={<MyDesigns />} />
          {/* Select Pages */}
          <Route path='/select-design' element={<Design />} />
          <Route path='/edit-design/:id' element={<Design />} />
          <Route path='/select/room' element={<Room />} />
          <Route path='/select/style' element={<Style />} />
          <Route path='/select/feel' element={<Feel />} />
          <Route path='/select/styleing' element={<Styleing />} />
          <Route path='/select/colour-preference' element={<ColourPreference />} />
          <Route path='/select/colour' element={<Colour />} />
          <Route path='/select/materials' element={<Materials />} />
          <Route path='/select/furniture' element={<Furniture />} />
          <Route path='/select/room-layout' element={<RoomLayout />} />
          <Route path='/select/empty-room' element={<EmptyRoom />} />
          {/* ---- */}
          <Route path='/our-designers' element={<OurDesigners />} />
          <Route path='/designer-profile/:id' element={<DesignerProfile />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/loading' element={<LoadingPage />} />
          <Route path='/products' element={<Products />} />
          <Route path='/product-details/:id' element={<ProductDetailsPage />} />
          <Route path='/your-design' element={<YourDesign />} />
          <Route path='/how-it-works' element={<HowItWorks />} />
          <Route path='/purchased-items' element={<PurchasedItems />} />
          <Route path='/top-designs/:id' element={<TopDesigns />} />

          <Route path='/razorpay' element={<RazorpayIntegration />} />
          <Route path='/viewallnotification' element={<Viewallnotification />} />

          <Route path='/*' element={<ErrorPage />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
