import React from 'react'

const Posts = () => {
  return (
    <section>
      <div className='index-section'>
        <div className='index-post'>
          <img src={require("../../assets/images/p3.png")} alt='index-post' className='index-post-img1' />
          <div className='index-post-info'>
            <div className='info-head'>
              <h2>What we can do for our <span style={{ color: "var(--primary)" }}>beloved</span> customers</h2>
              <p>At Stikdesign, we emphasize delivering on-time and on-budget projects with excellent designs. The services range from online consultations through visualization for our design ideas & tales, blogs, and assistance services.</p>
            </div>

            <div className='post-info-sub'>
              <h4>01</h4>
              <div>
                <h4>AI-based spatial design entity</h4>
                <p>A user-friendly interface that allows you to browse through various interior designs by category and keywords. Enabling pre-produced advanced design outcomes, creating & visualizing spatial designs more efficiently.</p>
              </div>
            </div>

            <div className='post-info-sub'>
              <h4>02</h4>
              <div>
                <h4>Expertise in customization of designs and supervision</h4>
                <p>Providing optimized design solutions, specifically customized according to client requirements. A creative peer including architects and designers at your fingertips. Also, our turnkey project management strategy avoids your on-site supervision difficulties.</p>
              </div>
            </div>

            <div className='post-info-sub'>
              <h4>03</h4>
              <div>
                <h4>Project data integration & support</h4>
                <p>Analyze & process the data and regulatory requirements in a project including technical drawings and specifications at the best price.</p>
              </div>
            </div>

          </div>
        </div>

        <div className='index-post'>
          <div className='index-post-info index-post-info2'>
            <h2>We Provide You The Best Experience.</h2>
            <p>We offer a range of options to meet your specific demands, including a product suggestion quiz. This quiz is an ideal tool for e-commerce websites to increase sales. Users find it quick and enjoyable and value it highly when selecting the best product for their needs.</p>
            <button className='button'>Learn more</button>
          </div>
          <img src={require("../../assets/images/p2.png")} alt='index-post' className='index-post-img2' />
        </div>

        <div className='index-post'>
          <img src={require("../../assets/images/p1.png")} alt='index-post' className='index-post-img3' />
          <div className='index-post-info'>
            <h2>We Provide You The Best Experience.</h2>
            <p>We offer a range of options to meet your specific demands, including a product suggestion quiz. This quiz is an ideal tool for e-commerce websites to increase sales. Users find it quick and enjoyable and value it highly when selecting the best product for their needs.</p>
            <button className='button'>Learn more</button>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Posts