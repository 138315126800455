import { CREATE_CONTACT_US_URL, LIST_BROCHURE_URL, LIST_CATEGORY_ROOM_URL, LIST_FAQ_URL, LIST_ROOM_DESIGNS_DETAILS_URL, LIST_ROOM_DESIGNS_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const listFaq = (search) => onResponse => {
    try {
        axiosInstance.get(LIST_FAQ_URL + '?search=' + search)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryRooms = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_ROOM_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listDesignsByRooms = (room_id) => onResponse => {
    try {
        axiosInstance.get(LIST_ROOM_DESIGNS_URL + '?room_id=' + room_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listDesignsByRoomsDetails = (id) => onResponse => {
    try {
        axiosInstance.get(LIST_ROOM_DESIGNS_DETAILS_URL + '?id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createContactUs = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CONTACT_US_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const listBrochure = () => onResponse => {
    try {
        axiosInstance.get(LIST_BROCHURE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};