import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

const Service = () => {
  return (
    <section>
      <div className='site-wrapper index-section'>
        <div className='service-top'>
          <h2 className='text-capitalize'>What we serve</h2>
          {/* <h2>We serve the best for you (what we serve )</h2> */}
          <img src={require("../../assets/images/scrool.png")} alt='scrool' className='scrool-img' />
          <div>
            <p>We provide a range of possibilities that satisfy your particular space desires. Clients can better choose the ideal product, which also lessens the challenge of independent research. Thanks! for these personalized recommendations. Additionally, a limited variety of suggested products helps buyers avoid choice paralysis and facilitates decision-making.</p>
            {/* <span>Read more</span><FontAwesomeIcon icon={faShareNodes} className="icon-color" /> */}
          </div>
        </div>
        <div className='services'>
          <div className='services-head'>
            <h2>We Provide You The Best Experience.</h2>
            <p>We offer a range of solutions that cater to your unique needs. The 3D design is beyond simple & as clear as a day, making it ideal for anyone to process & experience the visual.</p>
            <button className='button'>Hire an expert</button>
          </div>
          <div>
            <img src={require("../../assets/images/service-bg.png")} alt='scrool' className='scrool-bg-img' />
            <div className='service-list'>

              <div className='service-info service-info1'>
                <h1>1</h1>
                <h3>Select the options & categories required for your idea and generate a design.</h3>
                <p>Our site is enabled with pre-produced design models, to generate options according to your ideas. The customer can generate the design to mix and match the interior assets, to generate a customized design.</p>
              </div>

              <div className='service-info service-info2'>
                <h1>2</h1>
                <h3>Save and download the outcome.</h3>
                <p>The design idea will be all set to be the space of your dreams.</p>
              </div>

              <div className='service-info service-info3'>
                <h1>3</h1>
                <h3>Purchase the interior furniture & fixtures in your design, without consulting any other online /offline stores.</h3>
                <p>We provide you with the complete data of the interior assets used, including the specifications and options, making it way easier to purchase them and practically implement the design.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Service