import { GET_OUR_DESIGNER_LISTING } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getOurDesignerListing = () => onResponse => {
    try {
        axiosInstance.get(GET_OUR_DESIGNER_LISTING)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getOurDesignerSingle = (designer_id) => onResponse => {
    try {
        axiosInstance.get(GET_OUR_DESIGNER_LISTING + '?designer_id=' + designer_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};