import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../components/layout'
import { listDesignsByRooms, listDesignsByRoomsDetails } from '../Redux/Home/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const TopDesigns = () => {
    const { id } = useParams()
    const [designs, setDesigns] = useState();
    const [show, setShow] = useState(false)
    const [data, setData] = useState({})

    const onViewDesignDetails = (design) => {
        listDesignsByRoomsDetails(design.id)((response) => {
            if (response.status) {
                setData(response?.data?.results[0])
                console.log(response?.data?.results[0]);
                setShow(true)
            }
        });
    }

    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        listDesignsByRooms(id)((response) => {
            if (response && response.status) {
                setDesigns(response?.data?.results)
            }
        });
    }, [])

    return (
        <Layout>
            <div className='site-wrapper'>
                <h3 className='my-designs-title'>Top Designs</h3>
                <div className='my-designs-cards'>
                    {designs && designs.length > 0 ? (
                        designs.map((design, index) => (
                            <div className="product-card" key={index}>
                                <img src={design?.interior_image} alt={design?.title} />
                                <div>
                                    <h3>{design?.title}</h3>
                                    <button className='button' onClick={() => onViewDesignDetails(design)}>View Details</button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='mt-5 ms-2'>
                            <b>No data found</b>
                        </p>
                    )}
                    {show &&
                        <div className="popup-box">
                            <div className="design-box">
                                <div className="register-info">
                                    <div>
                                        <h3>Selections</h3>
                                        <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-center'>
                                    <table className='table-listings'>
                                        <tr>
                                            <th>Category</th>
                                            <th>Selections</th>
                                        </tr>
                                        <tr><td>Room</td><td>{data?.select_room_type?.room_title}</td></tr>
                                        <tr><td>Styles</td><td>{data?.select_style_type?.style_title}</td></tr>
                                        <tr><td>Feels</td><td>{data?.select_feels_type?.feels_title}</td></tr>
                                        <tr><td>Interior stylings</td><td>{data?.select_interiorstyling?.interior_title}</td></tr>
                                        <tr><td>Colour preferences</td><td>{data?.select_colour_preference_type?.colour_preference_title}</td></tr>
                                        <tr><td>Colours</td><td>{data?.select_color?.colour_title}</td></tr>
                                        <tr><td>Material combinations</td><td>{data?.select_material?.material_title}</td></tr>
                                        {/* <tr><td>Furnitures</td><td>{data?.furnitures}</td></tr> */}
                                        <tr><td>Room shapes</td><td>{data?.select_roomshapes?.room_shape_title}</td></tr>
                                        <tr><td>Room Layouts</td><td>{data?.select_roomlayout?.room_layout_title}</td></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default TopDesigns