import React, { useState, useEffect, useRef, Fragment } from "react";

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShareAlt, faArrowsLeftRight, faRightLeft, faDownload, faDotCircle, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import html2canvas from 'html2canvas';
import { downloadCustomerDesign, getSingleDesignData, getTypologyData, listAllCategoryProducts, listCategoryRoomShape, orderPaymentDesign, saveCustomerDesign, wishListCustomerDesign } from "../Redux/Design/action";
import { exportComponentAsJPEG } from 'react-component-export-image';
import { RWebShare } from "react-web-share";
import LoadingPage from "./loading-page";
import { BASE_URL } from "../api";


const YourDesign = (props) => {

  const [category, setCategory] = useState();
  const [update, setUpdate] = useState(true);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(true)
  const [designId, setDesignId] = useState('');
  const [selections, setSelections] = useState(false)
  const componentRef = useRef(null);
  const selectionRef = useRef(null);









  const [expanded, setExpanded] = useState(false)
  const optionIcons = (
    saved &&
    <div className='your-design-img-icons' id='design-icons'>
      <button onClick={() => downloadDesign()}> <FontAwesomeIcon icon={faDownload} /></button>
      <button>
        {expanded ?
          <FontAwesomeIcon icon={faHeart} style={{ color: "#DF300A" }} /> :
          <FontAwesomeIcon onClick={() => addToWishList()} icon={faHeart} style={{ color: "#0C0B0B" }} />}
      </button>
      <RWebShare
        data={{
          text: 'Create your design',
          url: BASE_URL,
          title: 'Create custom interior design',
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <button> <FontAwesomeIcon icon={faShareAlt} /></button>
      </RWebShare>
    </div>

  )
  const addToWishList = () => {
    let formData = new FormData();
    formData.append('my_design', designId)
    wishListCustomerDesign({ formData })((response) => {
      if (response && response.status) {
        setExpanded(true)
      }
    });
  }


  // Options details

  const [active, setActive] = useState()
  const [options, setOptions] = useState()

  let optRef = useRef()

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [similiarProducts, setSimiliarProducts] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [activeProduct, setActiveProduct] = useState();
  const [activeShape, setActiveShape] = useState();
  const { selectedStyle,
    selectedRoom,
    selectedFeel,
    selectedInteriorStyle,
    selectedColorPreference,
    selectedColor,
    selectedMaterial,
    selectedRoomLayout,
    selectedFurniture,
    selectedRoomShape,
    selectedTypology,
    setSelectedRoom,
    setSelectedStyle,
    setSelectedFeel,
    setSelectedInteriorStyle,
    setSelectedColorPreference,
    setSelectedColor,
    setSelectedMaterial,
    setSelectedRoomLayout,
    setSelectedFurniture,
    setSelectedRoomShape,
    id,
    setIsPremium,
    isPremium
  } = props;
  useEffect(() => {
    localStorage.setItem('on_page', 'design')
    if (id && id != undefined) {
      getSingleDesignData(id)((response) => {
        if (response && response.status) {
          const result = response?.data?.results[0];
          result?.furnitures?.map((furniture) => {
            furniture.product = furniture.product[0]
          })
          setData(response?.data?.results[0])
          setCategory(response?.data?.results[0]?.furnitures[0]?.furniture?.id)
          setSelectedRoom(response?.data?.results[0]?.select_room_type)
          setSelectedStyle(response?.data?.results[0]?.select_style_type)
          setSelectedFeel(response?.data?.results[0]?.select_feels_type)
          setSelectedInteriorStyle(response?.data?.results[0]?.select_interiorstyling)
          setSelectedColorPreference(response?.data?.results[0]?.select_colour_preference_type)
          setSelectedColor(response?.data?.results[0]?.select_color)
          setSelectedMaterial(response?.data?.results[0]?.select_material)
          setSelectedRoomLayout(response?.data?.results[0]?.select_roomlayout)
          setSelectedFurniture(response?.data?.results[0]?.furnitures)
          setSelectedRoomShape(response?.data?.results[0]?.select_roomshapes)
        }
      });
    }
    else {
      var furniture_id = [];
      selectedFurniture?.map(furniture => {
        furniture_id.push(furniture?.id);
      })
      getTypologyData(selectedTypology?.id)((response) => {
        if (response && response.status) {
          let smproducts = []
          setData(response?.data?.results[0])
          console.log(response?.data?.results[0]?.furnitures[0]?.furniture?.id);
          setCategory(response?.data?.results[0]?.furnitures[0]?.furniture?.id)
          response?.data?.results[0]?.furnitures?.map(furniture => {
            if (!smproducts?.find((product) => product.id === furniture?.product?.id)) {
              smproducts.push(furniture?.product)
            }
          })
          setSimiliarProducts(smproducts)
          setLoading(false)
        }
        else {
          setLoading(false)
        }
      });
    }

  }, [])
  useEffect(() => {
    if (category) {
      if (category == 'bg') {
        listCategoryRoomShape(selectedRoom?.id, selectedStyle?.id, selectedFeel?.id, selectedInteriorStyle?.id, selectedColorPreference?.id, selectedColor?.id, selectedMaterial?.id, selectedRoomLayout?.id)((response) => {
          if (response && response.status) {
            setShapes(response?.data?.results)
          }
        });
      }
      else {
        listAllCategoryProducts(category)((response) => {
          if (response && response.status) {
            setProducts(response?.data?.results)
            // setActive(response?.data?.results[0]?.furnitures[0]?.furniture?.id)
          }
        });
      }
    }

  }, [category])
  const swapProduct = () => {
    // setUpdate(false)
    var designs = data;
    let smproducts = []
    if (designs.furnitures[active]) {
      console.log(activeProduct, active);
      designs.furnitures[active].product.center_image = activeProduct?.center_image
      designs.furnitures[active].product.id = activeProduct?.id
      designs.furnitures[active].product.image = activeProduct?.product_image
      designs.furnitures[active].product.left_image = activeProduct?.left_image
      designs.furnitures[active].product.right_image = activeProduct?.right_image
      designs.furnitures[active].product.title = activeProduct?.title
      // setTimeout(() => {
      setData(designs)
      setSaved(false)
      setUpdate(!update)
      designs?.furnitures?.map(furniture => {
        if (!smproducts?.find((product) => product.id === furniture?.product?.id)) {
          smproducts.push(furniture?.product)
        }
      })
      setSimiliarProducts(smproducts)
      // }, 1000);
    }
  }
  const swapShape = () => {
    // setUpdate(false)
    var designs = data;
    if (designs.select_roomshapes) {
      console.log(activeProduct, active);
      designs.select_roomshapes = activeShape


      setData(designs)
      setSaved(false)
      setUpdate(!update)
      // }, 1000);
    }
  }
  const downloadDesign = () => {


    downloadCustomerDesign(designId, isPremium)((response) => {
      if (response && response.status) {
        if (response?.data?.results && response?.data?.results[0] && response?.data?.results[0].custom_image) {
          const url = response?.data?.results[0].custom_image.replace('http://', 'https://')
          fetch(url)
            .then(response => {
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'mydesign';
                a.click();
              });
            });
        }


        let formData = new FormData();
        formData.append('my_design', designId);
        orderPaymentDesign({ formData })((response) => {
          if (response && response.status) {
            // console.log(response)

          }
        })
      }
    });

  }
  const saveDesign = () => {
    console.log(data);
    let furnitures = [];
    data.furnitures.map(item => {
      furnitures.push({
        furinture_id: item.furniture?.id,
        product_id: item?.product.id,
        positions: {
          height: item.position?.height,
          width: item.position?.width,
          top: item.position?.top,
          left: item.position?.left,
          type: item.position?.type
        }
      })
    })
    let formData = new FormData();
    if (designId && designId != '')
      formData.append('design_id', designId)
    formData.append('interior_id', id ? data?.id : selectedTypology?.id)
    formData.append('furnitures', JSON.stringify(furnitures))
    formData.append('roomshapes_type', data?.select_roomshapes?.id)
    formData.append('downloaded', false)
    saveCustomerDesign({ formData })((response) => {
      if (response && response.status) {
        setDesignId(response?.data?.id)
        setSaved(true)
        localStorage.removeItem('on_page')
        localStorage.removeItem('selectedRoom')
        localStorage.removeItem('selectedStyle')
        localStorage.removeItem('selectedFeel')
        localStorage.removeItem('selectedInteriorStyle')
        localStorage.removeItem('selectedColorPreference')
        localStorage.removeItem('selectedColor')
        localStorage.removeItem('selectedMaterial')
        localStorage.removeItem('selectedRoomLayout')
        localStorage.removeItem('selectedTypology')
      }
    });
  }
  console.log(data);
  return (
    <Fragment>
      {loading ?
        <LoadingPage />
        :
        <div className='site-wrapper'>
          <h3 className='designer-title'>Your design</h3>

          {options && (
            <div className='your-design-options-mobile' ref={optRef}>
              {category == 'bg' ?
                <Fragment>
                  {shapes?.map((shape, index) =>
                    data?.select_roomshapes?.id != shape?.id &&
                    <div key={index} className='your-design-img-options' onClick={() => setActiveShape(shape)}>
                      <div className="unactive" >
                        {activeShape?.id == shape?.id ?
                          <div className='active-option'>
                            <img src={shape?.room_shape_image} alt="sofa" className='design-img-option' />
                            <div>
                              <span>{shape?.name}</span>
                              <span>{shape?.rate}</span>
                            </div>
                            <div>
                              <span className='details'>Details</span>
                              <div className='swap' onClick={() => swapShape()}><FontAwesomeIcon icon={faRightLeft} />Swap</div>
                            </div>
                          </div>
                          :
                          <img src={shape?.room_shape_image} alt="sofa" className='design-img-option' />
                        }
                      </div>
                    </div>
                  )}
                </Fragment>
                :
                <Fragment>
                  {products?.map((product, index) =>
                    data?.furnitures[active]?.product?.id != product?.id &&
                    <div key={index} className='your-design-img-options' onClick={() => setActiveProduct(product)}>
                      <div className="unactive" >
                        {activeProduct?.id == product?.id ?
                          <div className='active-option'>
                            <img src={product?.product_image[0].image} alt="sofa" className='design-img-option' />
                            <div>
                              <span>{product?.name}</span>
                              <span>{product?.rate}</span>
                            </div>
                            <div>
                              <span className='details'>Details</span>
                              <div className='swap' onClick={() => swapProduct()}><FontAwesomeIcon icon={faRightLeft} />Swap</div>
                            </div>
                          </div>
                          :
                          <img src={product?.product_image[0].image} alt="sofa" className='design-img-option' />
                        }
                      </div>
                    </div>
                  )}
                </Fragment>
              }
            </div>
          )}

          <div className='your-design-hero' >
            {/* {update && */}
            <div className='your-design-img-wrap' ref={componentRef}>
              {data?.furnitures?.map((furniture, index) =>
                <div>
                  <img width={leftConvert(furniture?.position?.width)} alt="design" src={furniture?.position?.type == 'CENTER' ? furniture?.product?.center_image : furniture?.position?.type == 'LEFT' ? furniture?.product?.left_image : furniture?.product?.right_image} style={{ position: 'absolute', top: topConvert(furniture?.position?.top), left: leftConvert(furniture?.position?.left) }} />
                  {/* <button className={active == index ? 'yd-btn category-btn' : 'yd-btn yd-btn-active category-btn'} onClick={() => { setActive(index); setCategory(furniture?.furniture?.id) }} style={{ position: 'absolute', top: topConvert(furniture?.position?.top + 50), left: leftConvert(furniture?.position?.left + (furniture?.position?.width / 2)) , zIndex: 9999 }}></button> */}
                  <div onClick={() => { setActive(index); setCategory(furniture?.furniture?.id) }} style={{ position: 'absolute', top: topConvert(furniture?.position?.top + 50), left: leftConvert(furniture?.position?.left + (furniture?.position?.width / 2)), zIndex: 999 }}><div className={active == index ? "yd-select-active" : "yd-select"}></div></div>
                </div>
              )}
              <div onClick={() => { setActive('bg'); setCategory('bg') }} style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 999 }}><div className={active == 'bg' ? "yd-select-active" : "yd-select"}></div></div>
              <img src={data?.select_roomshapes?.room_shape_image} alt="your-design" height='600px' width='1000px' className='your-design-img' />
              {optionIcons}
              <button className='options-responsive-btn' onClick={() => setOptions(!options)}>
                <FontAwesomeIcon icon={faArrowsLeftRight} />
              </button>
            </div>
            {/* } */}
            <div className='your-design-options-parent'>
              {category == 'bg' ?
                <Fragment>
                  {shapes?.map((shape, index) =>
                    data?.select_roomshapes?.id != shape?.id &&
                    <div key={index} className='your-design-img-options' onClick={() => setActiveShape(shape)}>
                      <div className="unactive" >
                        {activeShape?.id == shape?.id ?
                          <div className='active-option'>
                            <img src={shape?.room_shape_image} alt="sofa" className='design-img-option' />
                            <div>
                              <span>{shape?.name}</span>
                              <span>{shape?.rate}</span>
                            </div>
                            <div>
                              <span className='details'>Details</span>
                              <div className='swap' onClick={() => swapShape()}><FontAwesomeIcon icon={faRightLeft} />Swap</div>
                            </div>
                          </div>
                          :
                          <img src={shape?.room_shape_image} alt="sofa" className='design-img-option' />
                        }
                      </div>
                    </div>
                  )}
                </Fragment>
                :
                <Fragment>
                  {products?.map((product, index) =>
                    data?.furnitures[active]?.product?.id != product?.id &&
                    <div key={index} className='your-design-img-options' onClick={() => setActiveProduct(product)}>
                      <div className="unactive" >
                        {activeProduct?.id == product?.id ?
                          <div className='active-option'>
                            <img src={product?.product_image[0]?.image} alt="sofa" className='design-img-option' />
                            <div>
                              <span>{product?.name}</span>
                              <span>{product?.rate}</span>
                            </div>
                            <div>
                              <span className='details'>Details</span>
                              <div className='swap' onClick={() => swapProduct()}><FontAwesomeIcon icon={faRightLeft} />Swap</div>
                            </div>
                          </div>
                          :
                          <img src={product?.product_image[0]?.image} alt="sofa" className='design-img-option' />
                        }
                      </div>
                    </div>
                  )}
                </Fragment>
              }
            </div>
          </div>


          <section className='your-design-items'>


            <div className='cards-wrap'>
              <h3>Materials And Furnitures</h3>
              <div className='your-design-cards'>
                {similiarProducts?.map(product =>
                  <Link to={'/product-details/' + product?.id} key={id}>
                    <div className="your-design-card">
                      <img src={product?.image && product?.image[0] && product?.image[0]?.image && product?.image[0]?.image} alt="your designs" />
                      <p>{product?.furniture_title}</p>
                      <h3>{product?.title}</h3>
                      <span className='price'>₹ {2000}</span>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </section>
          <div className='breadcrumb'>
            <div onClick={() => setSelections(true)}>Your selections<FontAwesomeIcon className="ms-1" icon={faChevronUp} />

              {/* <p>
              {selectedRoom?.room_title},
              {selectedStyle?.style_title},
              {selectedFeel?.feels_title},
              {selectedInteriorStyle?.interior_title},
              {selectedColorPreference?.colour_preference_title},
              {selectedColor?.colour_title},
              {selectedMaterial?.material_title},
              {selectedRoomLayout?.room_layout_title},
              {selectedFurniture?.map(furniture =>
                furniture?.furniture_title ? furniture?.furniture_title + ',' : furniture?.furniture?.furniture_title
              )}
              {selectedRoomShape?.room_shape_title},
            </p> */}
            </div>
            {selections &&
              <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
                <div className="d-flex flex-row justify-content-end pe-4 pt-2">
                  <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                  <div className="selection-badge">
                    {selectedRoom?.room_title}
                  </div>
                  <div className="selection-badge">
                    {selectedStyle?.style_title}
                  </div>
                  <div className="selection-badge">
                    {selectedFeel?.feels_title}
                  </div>
                  <div className="selection-badge">
                    {selectedInteriorStyle?.interior_title}
                  </div>
                  <div className="selection-badge">
                    {selectedColorPreference?.colour_preference_title}
                  </div>

                  <div className="selection-badge">
                    {selectedColor?.colour_title}
                  </div>
                  <div className="selection-badge">
                    {selectedMaterial?.material_title}
                  </div>
                  <div className="selection-badge">
                    {selectedRoomLayout?.room_layout_title}
                  </div>
                </div>
              </div>
            }
            <div>
              {saved ?
                <button className='button-saved' type="button">Saved</button>
                :
                <button className='button' type="button" onClick={() => saveDesign()}>Save</button>
              }
              {/* <div className='btn btn-light ms-5' type="button" onClick={() => { saveDesign(); }}><FontAwesomeIcon icon={faDownload}  /></div> */}
            </div>
          </div>
        </div>
      }
    </Fragment>

  )
}
const topConvert = (top) => {
  return (top / 6) + '%'
}
const leftConvert = (left) => {
  return (left / 10) + '%'
}

export default YourDesign