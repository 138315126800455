import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Layout from '../components/layout'
import { addPaymentRazorpay, customerPackage, getPackageDetails } from '../Redux/Package/action'

const Pricing = () => {

  const [packages, setPackages] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    getPackageDetails()((response) => {
      if (response && response.status) {
        console.log(packages);
        setPackages(response?.data?.results)
        // navigate('/select-design')
      }
    });
  }, []);
  // const [amount, setamount] = useState('');


  const priceCard = packages.map((package_data, index) => {
    const lucideCheck = <img src={require("../assets/images/lucide-check.png")} alt='lucide-check' style={{ width: "25px", height: "25px" }} />




    // Razorpay starts

    const handleSubmit = (e, amount, id) => {
      e.preventDefault();
      if (amount === "") {
        alert("please enter amount");
      } else {
        var options = {
          key: "rzp_live_hedZ82EzXWRbn7",
          key_secret: "dcYvuZAYuluKt6mLwbxu8mhP",
          amount: amount * 100,
          currency: "INR",
          name: "STIKCON",
          description: "We take delight in executing projects with home design and remodeling in short order with top-notch designs at an affordable price.",
          handler: function (response) {
            console.log(response)
            addPaymentRazorpay(response.razorpay_payment_id, amount)((response) => {
              if (response && response.status) {
                let formData = new FormData();
                formData.append("package", id);
                customerPackage({ formData })((response) => {
                  if (response && response.status) {
                    navigate('/select-design')
                  }
                });
              }
            });
            // alert(response.razorpay_payment_id);
          },
          prefill: {
            name: "",
            email: "stikcon@gmail.com",
            contact: "8899002211"
          },
          notes: {
            address: "8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi."
          },
          theme: {
            color: "#3399cc"
          }
        };
        var pay = new window.Razorpay(options);
        pay.open();
      }
    }

    // Razorpay ends

    return (
      <div key={package_data?.id} className="price-card">
        <h4>{package_data?.name}</h4>
        <div className='price'><h4>₹ {package_data?.rate - package_data?.discount_rate}</h4> <p>₹ <span>{package_data?.rate}</span> / {package_data?.limit} {" "} Limit</p></div>
        <div className='price-options'>
          <div className='price-option'>{lucideCheck} <p>{package_data?.details[0]?.details}</p></div>
        </div>
        <div className='price-btn'>
          <button className='button' onClick={(e) => handleSubmit(e, package_data?.rate - package_data?.discount_rate, package_data?.id)}>Select Package</button>
          {/* <Link to="/" className='view-link'>View Package Details</Link> */}
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='designer-title'>Select a package for your room</h3>
        <div className='pricing-cards'>{priceCard}</div>
      </div>
    </Layout>
  )
}

export default Pricing