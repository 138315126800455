import React, { useEffect, useRef, useState } from 'react'
import SelectCard from '../../components/select-options/select-card'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listCategoryColor } from '../../Redux/Design/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons'

const Colour = (props) => {

  const [data, setData] = useState([]);
  const { setActive, selectedStyle, selectedRoom, selectedFeel, selectedInteriorStyle, selectedColorPreference, selectedColor, setSelectedColor, packageData, setShow,setIsPremium } = props;
  const selectionRef = useRef(null);
  const [selections, setSelections] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('on_page','color')
    listCategoryColor( selectedFeel?.id)((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.colour_subscription == 1) {
      setSelectedColor(info)
      localStorage.setItem('selectedColor',JSON.stringify(info))
      setIsPremium(true)
    }
    else {
      if (packageData?.package_available) {
        setSelectedColor(info)
        localStorage.setItem('selectedColor',JSON.stringify(info))
      }
      else {
        setShow(true)
      }
    }

  }
  return (
      <div className='site-wrapper'>
        <SelectHead title="Colour" byNo="6" active='color_preference' setActive={setActive} />
        <div className='cards-four-list colour-images'>
          {data?.map((info, index) =>
            <button
              key={index}
              className={selectedColor?.id == String(info.id) ? "select-card-active" : "select-card"}
              id={info.id}
              onClick={() => selectData(info)}
            >
              <div className="select-type-active">
                <span>✓</span>
              </div>
              <div className="select-type">
                {info.colour_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
              </div>
              <img src={info.colour_image} alt={info.colour_title} className="select-image" />
              <h3 className="select-title">{info.colour_title}</h3>
            </button>
          )}
        </div>
        <div className='breadcrumb'>
          {/* <div>Your selections: <p>{selectedRoom?.room_title}, {selectedStyle?.style_title},{selectedFeel?.feels_title},{selectedInteriorStyle?.interior_title},{selectedColorPreference?.colour_preference_title}</p></div> */}
          <div onClick={() => setSelections(true)}>Your selections <FontAwesomeIcon className="ms-1" icon={faChevronUp} /></div>
        {selections &&
          <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
            <div className="d-flex flex-row justify-content-end pe-4 pt-2">
              <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
              <div className="selection-badge">
                {selectedRoom?.room_title}
              </div>
              <div className="selection-badge">
                {selectedStyle?.style_title}
              </div>
              <div className="selection-badge">
                {selectedFeel?.feels_title}
              </div>
              <div className="selection-badge">
                {selectedInteriorStyle?.interior_title}
              </div>
              <div className="selection-badge">
                {selectedColorPreference?.colour_preference_title}
              </div>

            </div>
          </div>
        }
          <div><button disabled={false} onClick={() => setActive('material')} className='button'>Next</button></div>
        </div>
      </div>
   
  )
}

export default Colour