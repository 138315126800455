import React, { useEffect, useState } from 'react';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { getTestimonialDetails } from '../../Redux/Testimonial/action';

const data = [
	{
		id: 1,
		image: require("../../assets/images/t1.png"),
		title: "Ashlynn Culhane",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo bibendum a quisque consequat luctus diam urna pharetra lobortis."
	},
	{
		id: 2,
		image: require("../../assets/images/t2.png"),
		title: "Madeleine Albright",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo bibendum a quisque consequat luctus diam urna pharetra lobortis."
	}
]



const Testimonials = () => {
	const [testimonails, setTestimonials] = useState([])

	const testimonialInfo = testimonails.map((testimonial_data, index) => {
		return (
			<div key={testimonial_data.id}>
				<div className='testimonial-info'>
					<img src={testimonial_data.image} alt={testimonial_data.image} />
					<div>
						<h3>{testimonial_data.name}</h3>
						<p>{testimonial_data.description}</p>
					</div>
				</div>
			</div>
		)
	})

	useEffect(() => {
		getTestimonialDetails()((response) => {
			if (response && response.status) {
				setTestimonials(response?.data?.results)
				// navigate('/select-design')
			}
		});
	}, []);
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	return (
		<section className='testimonials'>
			<div className='site-wrapper index-section'>
				<div className='section-top' style={{ marginBottom: "75px" }}>
					<span>— TESTIMONIAL</span>
					<h3>Take a look at our client’s happiness and recommendations.</h3>
				</div>
				<Slider {...settings}>
					{testimonialInfo}
				</Slider>
			</div>
		</section>
	)
}

export default Testimonials;