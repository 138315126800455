import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { Tab, Nav } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { createCustomerAddress, deleteCustomerProfile, getCustomerProfileData, updateCustomerProfile } from '../Redux/Profile/action';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddressModal from './profile-options/address-modal';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const Profile = () => {
  const [update, setUpdate] = useState(false)

  function Status() {
    const [userImage, setUserImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const validationSchema = Yup.object().shape({
      // name: Yup.string()
      //     .required('Required'),

      // rate: Yup.number()
      //     .required('Required'),
      // limit: Yup.number()
      //     .required('Required'),

    });
    const statusFormik = useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",

      },
      validationSchema,
      onSubmit: (values) => {
        console.log(values, userImage);
        let formData = new FormData();
        formData.append('name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        if (userImage != "" && userImage != null) {
          formData.append('image', userImage);
        }
        updateCustomerProfile({ formData })((response) => {
          if (response && response.status) {
            toast.success("Profile updated successfully", {
              position: toast.POSITION.TOP_RIGHT
            });
            localStorage.setItem('user', JSON.stringify(response.data))
            setUpdate(!update)
          }
          else {
            toast.error(response?.response?.data?.message ? response?.response?.data?.message : 'Something went wrong', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        });

      },
    });
    useEffect(() => {
      getCustomerProfileData(false)((response) => {
        if (response && response.status) {
          // setUser(response?.data?.results[0])
          statusFormik.setValues({
            first_name: response?.data?.results[0].name,
            last_name: response?.data?.results[0].last_name,
            email: response?.data?.results[0].email,
            phone: response?.data?.results[0].phone,
          })
          setImageUrl(response?.data?.results[0].image)
        }
      });

    }, [])
    const handleImageChange = (e) => {
      setUserImage(e.target.files[0])
      var imgUrl = URL.createObjectURL(e.target.files[0])
      setImageUrl(imgUrl)
    }
    return (
      <div className='personal-info'>
        <h4 className='profile-content-title'>Edit personal Information</h4>
        <Form onSubmit={statusFormik.handleSubmit}>
          <div className='img-update'>
            <img src={imageUrl ? imageUrl : require("../assets/images/t1.png")} alt='profile' />
            <input type="file" onChange={(e) => handleImageChange(e)} name='image' id="actual-btn" hidden />
            <label className='Upload-btn' for="actual-btn">Upload</label>
            {/* <button className=' button btn-delete'>Delete</button> */}
          </div>
          <div>
            <div className='contact-form personal-info-form'>
              <Form.Group controlId="formBasicText">
                <Form.Control type="text" value={statusFormik.values?.first_name} onChange={statusFormik.handleChange} name='first_name' placeholder="First Name" />
              </Form.Group>

              <Form.Group controlId="formBasicText">
                <Form.Control type="text" value={statusFormik.values?.last_name} onChange={statusFormik.handleChange} name='last_name' placeholder="Last Name" />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" value={statusFormik.values?.email} onChange={statusFormik.handleChange} name='email' placeholder="Email" />
              </Form.Group>

              <Form.Group controlId="formBasicText">
                <Form.Control type="text" value={statusFormik.values?.phone} onChange={statusFormik.handleChange} name='phone' placeholder="Phone Number" />
              </Form.Group>
              <button type='submit' className="button">Save Changes</button>
            </div>
          </div>
        </Form>
      </div>
    )
  }

  function Address() {
    const [isOpen, setIsOpen] = useState(false)
    const [addresses, setAddresses] = useState([])
    const validationSchema = Yup.object().shape({
      place: Yup.string()
        .required('Required'),
      address: Yup.string()
        .required('Required'),
      address_line: Yup.string()
        .required('Required'),
      locality: Yup.string()
        .required('Required'),
      landmark: Yup.string()
        .required('Required'),
      state: Yup.string()
        .required('Required'),
      district: Yup.string()
        .required('Required'),

      phone: Yup.number()
        .required('Required'),
      pincode: Yup.number()
        .required('Required'),

    });
    const addressFormik = useFormik({
      initialValues: {
        place: "",
        address: "",
        address_line: "",
        locality: "",
        landmark: "",
        state: "",
        district: "",
        phone: "",
        pincode: "",

      },
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
        let formData = new FormData();
        formData.append('place', values.place);
        formData.append('address', values.address);
        formData.append('address_line', values.address_line);
        formData.append('locality', values.locality);
        formData.append('landmark', values.landmark);
        formData.append('state', values.state);
        formData.append('district', values.district);
        formData.append('alternate_mobile', values.phone);
        formData.append('pincode', values.pincode);
        createCustomerAddress({ formData })((response) => {
          if (response && response.status) {
            fetchData()
            handleCloseModal()
            addressFormik.resetForm()
          }
        });

      },
    });
    useEffect(() => {
      fetchData()

    }, [])
    const fetchData = () => {
      getCustomerProfileData(true)((response) => {
        if (response && response.status) {
          setAddresses(response.data?.results)
        }
      });
    }
    const handleCloseModal = () => {
      setIsOpen(false);
    }
    return (
      <div className='address'>
        <h4 className='profile-content-title'>Addresses</h4>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Address</Th>
                <Th>Address Line 2</Th>
                <Th>City</Th>
                <Th>State</Th>
                <Th>Zip</Th>
                <Th>Phone</Th>
              </Tr>
            </Thead>
            {addresses?.map((address, index) =>
              <Tbody>
                <Tr>
                  <Td>{address?.locality}</Td>
                  <Td>{address?.address}</Td>
                  <Td>{address?.address_line}</Td>
                  <Td>{address?.place}</Td>
                  <Td>{address?.state}</Td>
                  <Td>{address?.pincode}</Td>
                  <Td>{address?.alternate_mobile}</Td>
                </Tr>
              </Tbody>
            )}
          </Table>
          {/* <p>You currently do not have any addresses on file. </p> */}
          <button className="button" onClick={() => setIsOpen(true)}>Add New Address</button>
        </div>
        {isOpen && <AddressModal formik={addressFormik} handleClose={handleCloseModal} />}
      </div>
    )
  }

  function Email() {
    return (
      <div className='email'>
        <h4 className='profile-content-title'>Email Settings</h4>
        <div className='email-info'>
          <div>
            <input type="checkbox" id="Notifications" name="notifications" value="Notifications" />
            <label for="Notifications">Notifications</label>
          </div>
          <p>Allow email notifications</p>
          <button className="button">Update Email Settings</button>
        </div>
      </div>
    )
  }

  // function Delete() {

  //   const [error, setError] = useState(false);
  //   const navigate = useNavigate();
  //   const validationSchema = Yup.object().shape({
  //     email: Yup.string().email()
  //       .required('Required'),



  //   });
  //   const deleteFormik = useFormik({
  //     initialValues: {
  //       email: "",
  //     },
  //     validationSchema,
  //     onSubmit: (values) => {
  //       console.log(values);
  //       deleteCustomerProfile(values.email)((response) => {
  //         if (response && response.status) {
  //           localStorage.clear();
  //           navigate('/')
  //         }
  //       });

  //     },
  //   });

  //   return (
  //     <div className='delete'>
  //       <h4 className='profile-content-title'>Delete my account</h4>
  //       <div>
  //         <p>To delete your Stikcon account, please type your email address</p>
  //         <Form onSubmit={deleteFormik.handleSubmit}>
  //           <Form.Group controlId="formBasicEmail">
  //             <Form.Control type="email" name='email' onChange={deleteFormik.handleChange} isInvalid={deleteFormik.errors.email ? true :false} placeholder="Email" />
  //           </Form.Group>
  //           <button className="button" type='submit'>Delete my Account</button>
  //         </Form>
  //       </div>
  //     </div>
  //   )
  // }


  return (
    <Layout>
      <ToastContainer style={{ zIndex: 99999 }} />
      <div className='site-wrapper profile-page'>
        <h3 className='title'>My Account</h3>
        <section className='profile-tab-content'>
          <Tab.Container id="uncontrolled-tab-example" defaultActiveKey="personal">
            <div>
              <Nav variant="pills" className="tab-title">
                <Nav.Item>
                  <Nav.Link eventKey="personal">Personal Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="address">Address</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="email">Email preferences</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="delete">Delete my Account</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
            <div>
              <Tab.Content className='profile-content'>
                <Tab.Pane eventKey="personal">
                  <Status />
                </Tab.Pane>
                <Tab.Pane eventKey="address">
                  <Address />
                </Tab.Pane>
                <Tab.Pane eventKey="email">
                  <Email />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="delete">
                  <Delete />
                </Tab.Pane> */}
              </Tab.Content>
            </div>
          </Tab.Container>
        </section>
      </div>
    </Layout>
  )
}

export default Profile