import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Layout from '../components/layout';
import { Form, InputGroup } from 'react-bootstrap';
import { listFaq } from '../Redux/Home/action';


const Faq = () => {
  const [questions, setQuestions] = useState([])
  const [expanded, setExpanded] = useState('')
  const [search, setSearch] = useState('')
  useEffect(() => {
    listFaq(search)((response) => {
      if (response && response.status) {
        setQuestions(response?.data?.results)
      }
    });

  }, [search])

  return (
    <Layout>
      <div className='faq-questions site-wrapper'>
        <section className='page-head'>
          <span>FAQ</span>
          <h2>Frequently Asked Questions</h2>
          <p>We provide a variety of services, including project management, site monitoring, fabrication, kitchen, wallpaper, door and window, and garden work.</p>
          <InputGroup className='page-search'>
            <InputGroup.Text >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Type your search"
              aria-label="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </section>
        <section>
          {questions.map((question) => (
            <div className='question'>
              <div onClick={() => expanded == question?.id ? setExpanded('') : setExpanded(question?.id)}>
                <h4 className='question-title'>
                  {question?.question}
                </h4>
                <button className='faq-btn' >
                  {expanded == question?.id ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
                </button>
              </div>
              {expanded == question?.id && <p>{question?.answer}</p>}
            </div>
          ))}
        </section>
      </div>
    </Layout>
  )
};

export default Faq;