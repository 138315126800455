import React from 'react'
import Header from '../header/header'
import { Link, useNavigate } from 'react-router-dom'

const Hero = ({ setOpenLogin }) => {
  const navigate = useNavigate()
  const onStartDesign = () => {
    const logged = localStorage.getItem('is_logged')
    if (logged) {
      navigate('/select-design')
    }
    else {
      setOpenLogin(true)
    }
  }
  return (
    <section>
      {/* <Header indexHeader={true} /> */}
      <div className='hero-section'>
        <div className='hero-info'>
          <h2>Bringing Creative Interiors into Life</h2>
          <p>We introduce you to the first AI-based product & space design strategy, which gives life to your dream home. We assure you that the ideas will check every box of your requirements.</p>
          <button onClick={onStartDesign} className='button'>Get started</button>
        </div>
        <img src={require("../../assets/images/hero.png")} alt='Hero' />
      </div>
    </section>
  )
}

export default Hero