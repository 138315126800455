import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Layout from '../components/layout';
import { deleteNotification, getAllNotificationList, getNotificationList, markAllAsReadNotification } from '../Redux/Notification/action';

const Viewallnotification = () => {

    const [notification, setNotification] = useState([])

    useEffect(() => {
        getAllNotificationList()((response) => {
            if (response && response.status) {
                setNotification(response?.data?.results)
            }
        });
    }, []);
    const onClickDeleteNotification = (id) => {
        deleteNotification(id)((response) => {
            if (response && response.status) {
                getAllNotificationList()((response) => {
                    if (response && response.status) {
                        setNotification(response?.data?.results)
                    }
                });
                toast.success("Notification deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
            else{
                toast.danger("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
        });
    }
    const onClickmarkAllAsRead = () => {
        markAllAsReadNotification()((response) => {
            if (response && response.status) {
                getAllNotificationList()((response) => {
                    if (response && response.status) {
                        setNotification(response?.data?.results)
                    }
                });
            }
        });
    }
    return (
        <React.Fragment>
            <Layout>
            <ToastContainer style={{zIndex: 99999}} />
                <section>
                    <div className='container'>
                        <div className="row notification-container">
                            <h2 className="text-center">My Notifications</h2>
                            <p className="dismiss"><Button className="dismiss-all" onClick={() => onClickmarkAllAsRead()}>Read All</Button></p>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                {notification?.map((notification_data, index) =>
                                    <div className="card notification-card notification-invitation" key={index} style={{backgroundColor : notification_data?.is_read ? '#fff':'#dcdcdc'}} >
                                        <div className="card-body">
                                            <table>
                                                <tr>
                                                    <div className='card-notification-main'>
                                                        <td><div className="card-title">{notification_data?.title}</div></td>
                                                        <td><div className="card-desc">{notification_data?.description}</div></td>
                                                    </div>
                                                    <td>
                                                        <Button className="btn btn-danger dismiss-notification" onClick={() => onClickDeleteNotification(notification_data?.id)}>Delete</Button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </section>
            </Layout>
        </React.Fragment>
    )
}

export default Viewallnotification;