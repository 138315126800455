import React, { Component, useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Otp = (props) => {

  const { handleClose, setOtp, otp, onVerifyOtp, otpTo, resendOtp, formValues } = props;
  const [seconds, setSeconds] = useState(30);
  const handleChange = (otp) => {
    console.log(otp);
    setOtp(otp);
  }


  useEffect(() => {

    const intervalId = setInterval(() => {
      setSeconds(seconds => seconds - 1);
      if (seconds <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="otp-box">
      <div className="otp-info">
        <div>
          <h3>Enter Verification Code</h3>
          <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
        </div>
        <p>We have send you a verification code to <span style={{ color: "var(--primary)" }}>{otpTo}</span></p>
      </div>
      <div className="otp-inputs">
        <OtpInput
          containerStyle="inputs"
          value={otp}
          isInputNum={true}
          onChange={handleChange}
          numInputs={4}
          separator={false}
          inputStyle={{ width: "60px", height: "60px", borderRadius: "12px", border: "1px solid var(--muted)", color: "var(--muted)" }}
        />
        {seconds >= 0 ?
          <p>Resend code in {seconds}{" "}sec</p>
          :
          <p>Didn't recieve otp ?<span style={{ color: "var(--primary)" }} role='button' onClick={() => resendOtp(formValues, otpTo)}> RESEND OTP</span></p>
        }
      </div>
      <button className="button" onClick={() => onVerifyOtp(otp)}>Verify</button>
    </div>
  )

}
export default Otp