import { ADD_PAYMENT_RAZORPAY, CUSTOMER_PACKAGE, GET_PACKAGE_AVAILABLE, GET_PACKAGE_DETAILS } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const addPaymentRazorpay = (payment_id, amount) => onResponse => {
    try {
        axiosInstance.post(ADD_PAYMENT_RAZORPAY, { payment_id, amount })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const customerPackage = (props) => onResponse => {
    try {
        axiosInstance.post(CUSTOMER_PACKAGE, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getPackageDetails = () => onResponse => {
    try {
        axiosInstance.get(GET_PACKAGE_DETAILS)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getPackageAvailable = () => onResponse => {
    try {
        axiosInstance.get(GET_PACKAGE_AVAILABLE)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};