import React from 'react'
import Layout from '../components/layout';

const HowItWorks = () => {
  return (
    <Layout>
      <div className='howitworks-page site-wrapper'>
        <div className='howitworks-head'>
          <span>HOW IT WORKs</span>
          <h2>What we Do & How</h2>
          <p className='info'>Know everything before investing</p>
          <p className='description'>Our virtual design is the perfect tool for your interiors, to become live.  Let’s take a walk & know everything before the big investment.</p>
          {/* <p className='description'>we understand our clients are puzzled, when we tell them we’re a virtual architect firm and we serve you online. so here’s a summery of ”What we do” and “how”</p> */}
        </div>

        {/* Know How */}
        <div>
          <h3 className='know-how-title'>Know How</h3>
          <div className='know-how-section'>
            <img src={require("../assets/images/hit-v1.png")} alt='vector' className='hit-v1' />
            <img src={require("../assets/images/hit-v2.png")} alt='vector' className='hit-v2' />

            <div className='hit-card hit-p1-img'>
              <img src={require("../assets/images/hit-p1.png")} alt='vector' />
              <div className='hit-info'>
                <span>01</span>
                <p>Select a pre-designed plan based on your needs & ideas to place an order on the same. Now you're set to start your journey with us.</p>
                {/* <p>Select a plan as per your requirement and place an order.</p> */}
              </div>
            </div>

            <div className='hit-card hit-p2-img'>
              <img src={require("../assets/images/hit-p2.png")} alt='vector' />
              <div className='hit-info'>
                <span>02</span>
                <p>The pre-designed plan is a demo that requires some customization. So, you can list down the changes in a form provided by us.</p>
                {/* <p>List down the changes in your plan and submit it to us through the changes form.</p> */}
              </div>
            </div>

            <div className='hit-card hit-p3-img'>
              <img src={require("../assets/images/hit-p3.png")} alt='vector' />
              <div className='hit-info'>
                <span>03</span>
                <p>An exclusively customized plan is then carefully curated by our design team.</p>
                {/* <p>Floor plan is then designed as per the changes received by us through yourselves.</p> */}
              </div>
            </div>

            <div className='hit-card hit-p4-img'>
              <img src={require("../assets/images/hit-p4.png")} alt='vector' />
              <div className='hit-info'>
                <span>04</span>
                <p>The plan data is then uploaded to your dashboard, for viewing and further amendments. It is ready for download.</p>
                {/* <p>The amended floor plan is then uploaded back to your dashboard, wherein download the ready file.</p> */}
              </div>
            </div>

            <div className='hit-card hit-p5-img'>
              <img src={require("../assets/images/hit-p5.png")} alt='vector' />
              <div className='hit-info'>
                <span>05</span>
                <p>After the finalization, you can hire a Leveler and Site Supervision through MMH. </p>
                {/* <p>At this stage of your plan, you can hire a leveler and Site Supervision through MMH</p> */}
              </div>
            </div>
          </div>
        </div>

        {/* What We Do */}
        <div>
          <h3 className='what-wedo-title'>What We Do</h3>
          <div className='what-wedos'>
            <div className='what-wedo-bg'>
              <img src={require("../assets/images/hit-v3.png")} alt='vector' />
            </div>
            <div className='what-wedo-items'>

              <div className='what-wedo-item'>
                <div className='ww-item-info'>
                  <h4>1. Floor Plans</h4>
                  <span>Re-defining Rooms & Spaces</span>
                  <span>Basic interior layout; furniture, fixtures, etc.</span>
                  <span>Vastu-oriented design ideas</span>
                </div>
                <div className='ww-item-img'><img src={require("../assets/images/ww1.png")} alt='vector' /></div>
              </div>

              <div className='what-wedo-item'>
                <div className='ww-item-img'><img src={require("../assets/images/ww2.png")} alt='vector' /></div>
                <div className='ww-item-info'>
                  <h4>2. Exterior elevation</h4>
                  <span>Style: Modern, Modern extravagant, Colonial, Vernacular / Traditional, Contemporary.</span>
                  <span>2D drawings with material detailing and specifications.</span>
                  <span>2D & 3D elevations and views.</span>
                </div>
              </div>

              <div className='what-wedo-item'>
                <div className='ww-item-info'>
                  <h4>3. Landscaping</h4>
                  <p>Includes customized design of exterior & interior landscapes, green decor, passive green design elements, etc.</p>
                </div>
                <div className='ww-item-img'><img src={require("../assets/images/ww3.png")} alt='vector' /></div>
              </div>


              <div className='what-wedo-item'>
                <div className='ww-item-img'><img src={require("../assets/images/ww4.png")} alt='vector' /></div>
                <div className='ww-item-info'>
                  <h4>4 . Visual Animation & Walkthrough presentation</h4>
                  <p>A walkthrough or 3D animated display incorporating the newest, most cutting-edge software and technical expertise.</p>
                </div>
              </div>

              <div className='what-wedo-item'>
                <div className='ww-item-info'>
                  <h4>5. Structural Analysis data</h4>
                  <span>Soil Analysis</span>
                  <span>Structural layout ( column & beam layout)</span>
                  <span>Foundation detail and schedule</span>
                  <span>Plinth-level structural data</span>
                </div>
                <div className='ww-item-img'><img src={require("../assets/images/ww5.png")} alt='vector' /></div>
              </div>

              <div className='what-wedo-item'>
                <div className='ww-item-img'><img src={require("../assets/images/ww6.png")} alt='vector' /></div>
                <div className='ww-item-info'>
                  <h4>6. Working Drawings</h4>
                  <p>The detailed drawings include general plans& sections, kitchen & bathroom details, door & window specification drawings, etc.</p>
                  {/* <span>Doors, Windows details</span>
                  <span>Working details</span>
                  <span>Bathroom details</span>
                  <span>Kitchen details</span>
                  <span>Section details</span> */}
                </div>
              </div>

              <div className='what-wedo-item'>
                <div className='ww-item-info'>
                  <h4>7. Plumbing, Electrical, HVAC & Drainage details</h4>
                  <span>Pipeline circulation and water supply details</span>
                  <span>Electrical and plumbing layouts</span>
                  <span>Drainage layout</span>
                  <span>Fixture details</span>
                  <span>HVAC measures and equipment loads</span>
                  <span>AMP switch, socket, fans, lights, A/C, Geyser location details & height of placement</span>
                  <span>Most appropriate & efficient CCTV Camera position mapping</span>
                </div>
                <div className='ww-item-img'><img src={require("../assets/images/ww7.png")} alt='vector' /></div>
              </div>

              {/* <div className='what-wedo-item'>
                <div className='ww-item-img'><img src={require("../assets/images/ww8.png")} alt='vector' /></div>
                <div className='ww-item-info'>
                  <h4>8. Electric Layout</h4>
                  <span>Complete Electric Layout with Furniture placements</span>
                  <span>AMP Switch & Socket Location with Height Details</span>
                  <span>Fans & Lights Location</span>
                  <span>AC & Geyser and other heavy appliacnces Points & Location</span>
                  <span>CC TV Camera & Door Bells</span>
                  <span>Telephone Point</span>
                </div>
              </div> */}

              <div className='what-wedo-item'>
                <div className='ww-item-img'><img src={require("../assets/images/ww9.png")} alt='vector' /></div>
                <div className='ww-item-info'>
                  <h4>8. Interior Design</h4>
                  <div className='ww-last-item-flex'>
                    <div>
                      <span>Material Detailing</span>
                      <span>Furniture design & placement</span>
                      <span>The color palette, wall paint & texture design</span>
                      <span>Cladding details</span>
                    </div>
                    {/* <div>
                      <span>Shera Panel Board</span>
                      <span>Wooden Work, rafter</span>
                      <span>Wall Paper</span>
                      <span>Tiles</span>
                      <span>Wall paint with colour code</span>
                      <span>Textures</span>
                    </div> */}
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HowItWorks