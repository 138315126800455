import React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from 'react-bootstrap';
import { Col, Row, Tab, Nav } from 'react-bootstrap';

function Started() {
  return (
    <div className='help-content-info'>
      <div>
        <h2>Getting started</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
      <div>
        <h3>Login</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
      <div>
        <h3>Ipsum dolor sit</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
    </div>
  )
}

function Profile() {
  return (
    <div className='help-content-info'>
      <div>
        <h2>Profile</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
    </div>
  )
}

function Transaction() {
  return (
    <div className='help-content-info'>
      <div>
        <h2>Transaction</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
    </div>
  )
}

function Status() {
  return (
    <div className='help-content-info'>
      <div>
        <h2>Status</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur tempus feugiat orci. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque phasellus nunc facilisi dictum cursus massa. Lectus porttitor malesuada dolor volutpat tellus ut id fames. Tempus sem turpis id risus suspendisse facilisi tempus. A, gravida eu pellentesque</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp. Lectus porttitor malesuada dolor volutpat tellus ut id fames.</p>
      </div>
    </div>
  )
}

const Help = () => {
  return (
    <Layout>
      <div className='site-wrapper help-page'>
        <section className='page-head'>
          <span>HELP</span>
          <h2>Help Center</h2>
          <p>We are glad having you here looking for the answer</p>
          <InputGroup className='page-search'>
            <InputGroup.Text >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Type your search"
              aria-label="search"
            />
          </InputGroup>
        </section>
        <section className='help-tab-content'>
          <Tab.Container id="left-tabs-example" defaultActiveKey="started">
            <Row>
              <Col sm={12} md={12} lg={2}>
                <Nav variant="pills" className="tab-title">
                  <Nav.Item>
                    <Nav.Link eventKey="started">Getting started</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="profile">Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="transaction">Transaction</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="status">Status</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12} md={12} lg={10}>
                <Tab.Content className='tab-content'>
                  <Tab.Pane eventKey="started">
                    <Started />
                  </Tab.Pane>
                  <Tab.Pane eventKey="profile">
                    <Profile />
                  </Tab.Pane>
                  <Tab.Pane eventKey="transaction">
                    <Transaction />
                  </Tab.Pane>
                  <Tab.Pane eventKey="status">
                    <Status />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </section>
        <section className='help-form'>
          <div className='form-info'>
            <h2>Need support?</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci, enim mus tristique sit egestas. Nulla posuere tempus, turpis consectetur temp.</p>
          </div>
          <div className='form'>
            <Form className='contact-form'>
              <Form.Group controlId="formBasicText">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" />
              </Form.Group>
              <Form.Group controlId="ControlTextarea1">
                <Form.Label>Please enter the details of your request.</Form.Label>
                <Form.Control as="textarea" rows={4} />
              </Form.Group>
              <button className='button' style={{ padding: "10px 60px" }}>Send</button>
            </Form>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Help