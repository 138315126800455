import { GET_INVOICE_DETAILS_URL, GET_PAYMENT_LIST_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getPaymentListing = (id) => onResponse => {
    try {
        axiosInstance.get(GET_PAYMENT_LIST_URL + '?customer_id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPaymentInvoiceDetails = (user_id,order_id) => onResponse => {
    try {
        axiosInstance.get(GET_INVOICE_DETAILS_URL + '?user_id=' + user_id + '&order_id=' + order_id + '&package_invoice=true')
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};