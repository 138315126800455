import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { getOurDesignerListing } from '../Redux/OurDesigners/action';


const OurDesigners = () => {

  const [designer, setDesigner] = useState([])

  useEffect(() => {
    getOurDesignerListing()((response) => {
      if (response && response.status) {
        setDesigner(response?.data?.results)
        // navigate('/select-design')
      }
    });
  }, []);

  var settings = {
    className: "",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false
  };


  const cards = designer.map((designers_data, index) => {
    return (
      <div className='designer-card' key={index}>

        <Slider {...settings} className="slide-icons2" style={{}}>
          {designers_data?.portfolio.map((si, index) => {
            return <Link to={'/designer-profile/' + designers_data.id}><img src={si?.image} className='img-fluid' alt='slide' key={index} style={{width:'100%'}} /></Link>
          })}
        </Slider>
        <div className='designer-card-info'>
          <img src={designers_data?.ourimage} alt='slide' />
          <div>
            <h4>{designers_data?.name}</h4>
            <span>{designers_data?.description}</span>
          </div>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='designer-title'>Meet our Designers</h3>
        <div className='designers'>
          {cards}
        </div>
      </div>
    </Layout>
  )
}

export default OurDesigners