import React, { useEffect, useState } from 'react'
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Layout from '../components/layout';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faHeart, faShareAlt, faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getCategoryProductListing, getSingleProductListing } from '../Redux/Product/action';
import { removeWishListCustomerDesign, wishListCustomerDesign } from '../Redux/Design/action';
import { RWebShare } from 'react-web-share';
import { BASE_URL } from '../api';

const ProductDetailsPage = () => {
  const [expanded, setExpanded] = useState(false)
  const [product, setProduct] = useState({})
  const [categoryProducts, setCategoryProducts] = useState([])
  const { id } = useParams();

  var settingsSimilarProducts = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
    ]
  };

  const data = [
    { id: 1, image: require("../assets/images/dd01.png"), title: "Custom Brooklyn Sofa in Heathered Taupe", price: "23,000", description: "Pottery Barn", type: "Sale" },
    { id: 2, image: require("../assets/images/dd02.png"), title: "Custom Brooklyn Sofa in Heathered Taupe", price: "21,000", description: "Pottery Barn", type: "Sale" },
    { id: 3, image: require("../assets/images/dd03.png"), title: "Custom Brooklyn Sofa in Heathered Taupe", price: "25,000", description: "Pottery Barn", type: "Sale" },
    { id: 4, image: require("../assets/images/dd04.png"), title: "Custom Brooklyn Sofa in Heathered Taupe", price: "23,000", description: "Pottery Barn", type: "Sale" },
    { id: 5, image: require("../assets/images/dd05.png"), title: "Custom Brooklyn Sofa in Heathered Taupe", price: "24,000", description: "Pottery Barn", type: "Sale" },
  ]

  const similarProducts = categoryProducts?.map(info => {
    if (id != info.id) {
      return (
        <Link to={'/product-details/' + info?.id} >
          <div className="similar-products-card" key={info.id}>
            <div className="similar-products-info">
              <span className='type'>{info.type}</span>
              <img src={info?.product_image[0]?.image} alt="similar products" />
              <p className='text-black'>{info.name}</p>
              <h3 className='text-black'>{info.title}</h3>
              <span className='price text-black'>₹ {info.rate}</span>
            </div>
          </div>
        </Link>
      )
    }
  })

  const settings = {
    customPaging: function (i) {
      return (
        <span>
          <img src={require(`../assets/images/dd0${i + 1}.png`)}
            alt="Product-Detail" style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
        </span>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  const optionIcons = (
    <div className='details-info-head-icons'>
      <button>
        {expanded ?
          <FontAwesomeIcon icon={faHeart} onClick={() => onClickRemoveWishlist()} style={{ color: "#DF300A" }} /> :
          <FontAwesomeIcon icon={faHeart} onClick={() => onClickWishlist()} style={{ color: "#0C0B0B" }} />}
      </button>
      <RWebShare
        data={{
          text: product?.title,
          url: BASE_URL + "/product-details/" + product?.id,
          title: product?.title,
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <button> <FontAwesomeIcon icon={faShareAlt} /></button>
      </RWebShare>
    </div>
  )
  const onClickWishlist = () => {
    let formData = new FormData();
    formData.append('product', product?.id)
    wishListCustomerDesign({ formData })((response) => {
      if (response && response.status) {
        setExpanded(true)
      }
    });
  }
  const onClickRemoveWishlist = () => {
    let formData = new FormData();
    formData.append('product', product?.id)
    removeWishListCustomerDesign(product?.id)((response) => {
      if (response && response.status) {
        setExpanded(false)
      }
    });
  }
  useEffect(() => {
    if (id) {
      getSingleProductListing(id)((response) => {
        if (response && response.status) {
          setProduct(response.data?.results[0])
          setExpanded(response.data?.results[0]?.favorite)
          getCategoryProductListing(response.data?.results[0]?.prodcut_category)((response) => {
            if (response && response.status) {
              setCategoryProducts(response.data?.results)
            }
          });
        }
      });
    }
  }, [id])

  return (
    <Layout>
      <div className='product-details-page'>
        <div className='site-wrapper'>
          <section>
            <div className='details-page-hero'>
              <div className='details-slides'>
                <Slider {...settings}>
                  {product?.product_image?.map((image, index) =>
                    <img src={image?.image} alt='Product-Detail' className='details-slide-img' />
                  )}
                </Slider>
              </div>

              <div className='details-info details-info-orders'>
                <div className='details-info-head'>
                  <div>
                    <p>{product?.title}</p>
                    <h3>{product?.name}</h3>
                  </div>
                  <div className='option-icons-show'>{optionIcons}</div>
                </div>

                <div className="details-infos-lastorder">
                  <h4 style={{ marginBottom: "20px" }}>Product info</h4>
                  <ul className='info-list'>
                    <li>Name: <p>{product?.name}</p></li>
                    <li>Dimension: <p>{product?.dimension}</p></li>
                    <li>Material: <p>{product?.material}</p></li>
                    <li>Color: <p>{product?.colour}</p></li>
                  </ul>
                </div>

                <div className="details-infos-price">
                  <div className='option-hide-head'>
                    <div>
                      <h5>Price: <span>₹ {product?.rate}</span></h5>
                      <p>Shop via Amazon</p>
                    </div>
                    <div className='option-icons-hide'>{optionIcons}</div>
                  </div>
                  <button className='button price-button'>
                    <a href={product?.product_link} target='_blank' rel="noreferrer">Shop Now</a>
                  </button>
                </div>
              </div>

            </div>
          </section>
          <section className='similar-products'>
            <div>
              <h3>Description</h3>
              <p>{product?.description}.</p>
            </div>
            <div>
              <div className='similarP-section-top'>
                <h3>Similar Products</h3>
                {/* <Link to={"/"}>Explore All <FontAwesomeIcon icon={faLongArrowRight} /></Link> */}
              </div>
              <Slider {...settingsSimilarProducts} className="slide-icons">
                {similarProducts}
              </Slider>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ProductDetailsPage