import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/layout'
import { getPackageAvailable } from '../Redux/Package/action';
import Colour from './select-options/colour';
import ColourPreference from './select-options/colour-preference';
import EmptyRoom from './select-options/empty-room';
import Feel from './select-options/feel';
import Furniture from './select-options/furniture';
import Materials from './select-options/materials';
import Room from './select-options/room';
import RoomLayout from './select-options/room-layout';
import Style from './select-options/style';
import Styleing from './select-options/styleing';
import Typology from './select-options/typology';
import YourDesign from './your-design';


const Design = () => {
    const [active, setActive] = useState('')
    const [selectedRoom, setSelectedRoom] = useState({})
    const [selectedStyle, setSelectedStyle] = useState({})
    const [selectedFeel, setSelectedFeel] = useState({})
    const [selectedInteriorStyle, setSelectedInteriorStyle] = useState({})
    const [selectedColorPreference, setSelectedColorPreference] = useState({})
    const [selectedColor, setSelectedColor] = useState({})
    const [selectedMaterial, setSelectedMaterial] = useState({})
    const [selectedRoomLayout, setSelectedRoomLayout] = useState({})
    const [selectedFurniture, setSelectedFurniture] = useState([])
    const [selectedRoomShape, setSelectedRoomShape] = useState({})
    const [selectedTypology, setSelectedTypology] = useState({})
    const [packageData, setPackageData] = useState([])
    const [isPremium, setIsPremium] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { id } = useParams()
    const navigate = useNavigate();
    useEffect(() => {
        getPackageAvailable()((response) => {
            if (response && response.status) {
                console.log();
                setPackageData(response?.data[0])
            }
        });
        const on_page = localStorage.getItem('on_page');
        if (id && id != undefined) {
            setActive('design')
        }
        else if (on_page && on_page != '') {
            setActive(on_page)
            
            setSelectedRoom(localStorage.getItem('selectedRoom') ? JSON.parse(localStorage.getItem('selectedRoom')) : [])
            setSelectedStyle(localStorage.getItem('selectedStyle') ? JSON.parse(localStorage.getItem('selectedStyle')) : [])
            setSelectedFeel(localStorage.getItem('selectedFeel') ? JSON.parse(localStorage.getItem('selectedFeel')) : [])
            setSelectedInteriorStyle(localStorage.getItem('selectedInteriorStyle') ? JSON.parse(localStorage.getItem('selectedInteriorStyle')) : [])
            setSelectedColorPreference(localStorage.getItem('selectedColorPreference') ? JSON.parse(localStorage.getItem('selectedColorPreference')) : [])
            setSelectedColor(localStorage.getItem('selectedColor') ? JSON.parse(localStorage.getItem('selectedColor')) : [])
            setSelectedMaterial(localStorage.getItem('selectedMaterial') ? JSON.parse(localStorage.getItem('selectedMaterial')) : [])
            setSelectedRoomLayout(localStorage.getItem('selectedRoomLayout') ? JSON.parse(localStorage.getItem('selectedRoomLayout')) : [])
            setSelectedTypology(localStorage.getItem('selectedTypology') ? JSON.parse(localStorage.getItem('selectedTypology')) : [])
           
        }
        else{
            setActive('room')
        }
    }, []);
    useEffect(() => {
        var bot = document.getElementsByClassName('iticks-pop-button');
        var container = document.getElementsByClassName('iticks-notif-msg');
        if (bot && bot[0]) {
            bot[0].style.display = 'none';
        }
        if (container && container[0]) {
            container[0].style.display = 'none';
        }
        return () => {
            if (bot && bot[0]) {
                bot[0].style.display = 'block';
            }
            if (container && container[0]) {
                container[0].style.display = 'block';
            }
        }

    }, [])






    return (
        <Layout>
            {active == 'room' &&
                <Room
                    setActive={setActive}
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    packageData={packageData}
                    setShow={setShow}
                    setIsPremium={setIsPremium}
                />}
            {active == 'style' && <Style
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                setSelectedStyle={setSelectedStyle}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'feel' && <Feel
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                setSelectedFeel={setSelectedFeel}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'interiorStyle' && <Styleing
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                setSelectedInteriorStyle={setSelectedInteriorStyle}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'color_preference' && <ColourPreference
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                setSelectedColorPreference={setSelectedColorPreference}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'color' && <Colour
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'material' && <Materials
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                selectedMaterial={selectedMaterial}
                setSelectedMaterial={setSelectedMaterial}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {active == 'room_layout' && <RoomLayout
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                selectedMaterial={selectedMaterial}
                selectedRoomLayout={selectedRoomLayout}
                setSelectedRoomLayout={setSelectedRoomLayout}
                packageData={packageData}
                setShow={setShow}
                setIsPremium={setIsPremium}
            />}
            {/* {active == 'furniture' && <Furniture
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                selectedMaterial={selectedMaterial}
                selectedRoomLayout={selectedRoomLayout}
                selectedFurniture={selectedFurniture}
                setSelectedFurniture={setSelectedFurniture}
                packageData={packageData}
                setShow={setShow}
            />} */}
            {/* {active == 'room_shape' && <EmptyRoom
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                selectedMaterial={selectedMaterial}
                selectedRoomLayout={selectedRoomLayout}
                selectedFurniture={selectedFurniture}
                selectedRoomShape={selectedRoomShape}
                setSelectedRoomShape={setSelectedRoomShape}
                packageData={packageData}
                setShow={setShow}
            />} */}
            {active == 'typology' && <Typology
                setActive={setActive}
                selectedRoom={selectedRoom}
                selectedStyle={selectedStyle}
                selectedFeel={selectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                selectedColor={selectedColor}
                selectedMaterial={selectedMaterial}
                selectedRoomLayout={selectedRoomLayout}
                selectedFurniture={selectedFurniture}
                selectedRoomShape={selectedRoomShape}
                selectedTypology={selectedTypology}
                setSelectedTypology={setSelectedTypology}
                packageData={packageData}
                setShow={setShow}

            />}
            {active == 'design' && <YourDesign
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                selectedStyle={selectedStyle}
                setSelectedStyle={setSelectedStyle}
                selectedFeel={selectedFeel}
                setSelectedFeel={setSelectedFeel}
                selectedInteriorStyle={selectedInteriorStyle}
                setSelectedInteriorStyle={setSelectedInteriorStyle}
                selectedColorPreference={selectedColorPreference}
                setSelectedColorPreference={setSelectedColorPreference}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                selectedMaterial={selectedMaterial}
                setSelectedMaterial={setSelectedMaterial}
                selectedRoomLayout={selectedRoomLayout}
                setSelectedRoomLayout={setSelectedRoomLayout}
                selectedFurniture={selectedFurniture}
                setSelectedFurniture={setSelectedFurniture}
                selectedRoomShape={selectedRoomShape}
                setSelectedRoomShape={setSelectedRoomShape}
                selectedTypology={selectedTypology}
                packageData={packageData}
                setShow={setShow}
                id={id}
                setIsPremium={setIsPremium}
                isPremium={isPremium}
            />}

            <>
                <Modal className='premium_data' show={show} centered onHide={handleClose}>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <h3 className='premium'>Don't have premium</h3>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className='purchase'>
                            <Link className='purchase_btn' to='/pricing'>Purchase it</Link>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>




        </Layout>
    )
}

export default Design