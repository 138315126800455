import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Invoice from '../components/invoice';
import { getPaymentListing } from '../Redux/Payment/action';

const Payments = () => {

  const [activeView, setActiveView] = useState("")
  const [payments, setPayments] = useState([])
  const [paymentId, setPaymentId] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const togglePopup = () => {
    setActiveView(!activeView);
  }

  
useEffect(() => {
  
  getPaymentListing(user?.id)((response) => {
    if (response && response.status) {
      setPayments(response.data?.results)
    }
  });
}, [])


  return (
    <Layout>
      {activeView === "Invoice" && (<Invoice handleClick={togglePopup} paymentId={paymentId} />)}
      <div className='site-wrapper'>
        <h4 className='payments-title'>Payments</h4>
        <div style={{ overflowX: "auto" }}>
          <table className='payments-table'>
            <tr>
              <th>Sl. no</th>
              <th>Date</th>
              <th>Product</th>
              <th>Status</th>
              <th></th>
            </tr>
            {payments.map(t => 
                <tr key={t.id}>
                  <td>{t.id}</td>
                  <td>{t.date}</td>
                  <td>{t.package?.name}</td>
                  <td>{t.status ? 'Active':'Inactive'}</td>
                  {/* {t.product === "Success" ? ( */}
                    <td className='invoice-btn'>
                      <span onClick={() => {setPaymentId(t.id);setActiveView("Invoice");}}><FontAwesomeIcon icon={faDownload} />Invoice</span>
                    </td>
                  {/* ) : (<td></td>)} */}
                </tr>
            )}
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Payments