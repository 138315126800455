import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom'
import Layout from '../components/layout';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getOurDesignerSingle } from '../Redux/OurDesigners/action';

const DesignerProfile = () => {

  const { id } = useParams()
  const [designer, setDesigner] = useState([])

  useEffect(() => {
    if (id) {
      getOurDesignerSingle(id)((response) => {
        if (response && response.status) {
          setDesigner(response?.data?.results[0])
          // navigate('/select-design')
        }
      });
    }

  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4.1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      },
    ]
  };



  const portfolios = designer?.portfolio?.map((designers_data, index) => {
    return (
      <div className="portfolio-card" key={index}>
        <div className="portfolio-info">
          <img src={designers_data?.image} alt="portfolio" />
          <h3>{designers_data?.title}</h3>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <div className='site-wrapper'>
        <h3 className='designer-title'>
          <Link to="/our-designers">
            <FontAwesomeIcon icon={faAngleLeft} className="select-back-arrow" />
          </Link>
          Designer Profile
        </h3>
        <div className='designer-profile'>
          <h4>{designer?.name}</h4>
          <div className='profile-wrap'>
            <div className='profile-info'>
              <img src={designer?.ourimage ? designer?.ourimage : require("../assets/images/dp01.png")} alt="designer profile" />
            </div>
            <div className='profile-tabs'>
              <Tabs
                defaultActiveKey="About"
                id="uncontrolled-tab-example"
                className="nav-tabs"
              >
                <Tab eventKey="About" title="About">
                  <p>
                    {designer?.about}
                  </p>
                </Tab>
                <Tab eventKey="Qualification" title="Qualification">
                  <p>
                    {designer?.qualification}
                  </p>
                </Tab>
                <Tab eventKey="Experience" title="Experience">
                  <p>
                    {designer?.experience}
                  </p>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className='portfolio-slider'>
          <h3>Portfolio</h3>
          <Slider {...settings} className="slide-icons">
            {portfolios}
          </Slider>
        </div>
      </div>
    </Layout>
  )
}

export default DesignerProfile