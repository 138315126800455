import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from 'react-bootstrap';
import Svgloader from '../svg-loader';

const Login = (props) => {
  const { activeView, handleClose, formik,loading } = props;
  return (
    <div className="login-box">
      <div className="login-info">
        <img src={require("../../assets/images/logo.png")} alt='logo' />
        <FontAwesomeIcon icon={faClose} onClick={handleClose} className="close-icon" />
        <h3>Welcome Back!</h3>
        <p>Welcome! Please enter your details</p>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className='contact-form'>
          <Form.Group controlId="formBasicText">

            <>
              <Form.Control type="text" name='username' className={formik.errors?.username ? 'error-field' : ''} onChange={formik.handleChange} placeholder="Email / Phone number" />
              
            </>
            
          </Form.Group>
        </div>
        {loading ?
          <Svgloader />
          :
          <button className="button" type='submit'>Login</button>
        }
      </Form>
      <p className="sign-up-text">Don’t have an account?<span onClick={() => activeView("Signup")}>Sign up</span></p>
    </div>
  )
}

export default Login