import React, { Fragment, useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import { listCategoryRooms } from '../../Redux/Home/action';

const InteriorDesigns = () => {
  const [rooms, setRooms] = useState([])
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    listCategoryRooms()((response) => {
      if (response && response.status) {
        setRooms(response?.data?.results)
      }
    });
  }
  return (

    <section className='site-wrapper index-section' style={{ marginBottom: "60px" }}>
      <div className='interior-section-top'>
        <div className='section-top'>
          <span>— TOP OF</span>
          <h3>Interior Designs</h3>
        </div>
        {/* <Link to={"/"}>Explore All <FontAwesomeIcon icon={faLongArrowRight} /></Link> */}
      </div>
      <div className='section-images'>
        {rooms?.map((room, index) =>
          <Link to={'/top-designs/' + room?.id}>
            <div key={index} className="overlay-image">
              <h3 className='overlay-image-title'>{room?.room_title}</h3>
              <img src={room?.room_image} alt={room?.room_title} />
            </div>
          </Link>
        )}
      </div>
    </section>
  )
}

export default InteriorDesigns