
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { getPaymentInvoiceDetails } from '../Redux/Payment/action';


const Invoice = ({ handleClick, paymentId }) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const [payment, setPayment] = useState({});
  const invoiceData = [
    { id: 1, qty: 1, grossAmount: "Rs 490.00", discount: "Rs 54.00", otherCharges: "Rs 0.00", taxableAmount: "Rs 415.24", IGST: "Rs 20.76", totalAmount: "Rs 436.00" },
    { id: 2, qty: 2, grossAmount: "Rs 490.00", discount: "Rs 54.00", otherCharges: "Rs 0.00", taxableAmount: "Rs 415.24", IGST: "Rs 20.76", totalAmount: "Rs 436.00" }
  ]
  useEffect(() => {
    if (paymentId && paymentId != '') {
      getPaymentInvoiceDetails(user?.id, paymentId)((response) => {
        if (response && response.status) {
          setPayment(response.data?.results[0])
        }
      });
    }
  }, [paymentId])

  return (
    <div className="invoice-box">
      <div className='invoice-info-box'>
        <div className='invoice-head'>
          <div className='logo-section'>
            <img src={require("../assets/images/logo.png")} alt='logo' />
            <div>
              <span><FontAwesomeIcon icon={faDownload} />Pdf</span>
              <FontAwesomeIcon icon={faClose} onClick={handleClick} />
            </div>
          </div>
          <div className='invoice-data'>
            <div>
              <h5>Invoice Number:</h5>
              <p>{}</p>
            </div>
            <div>
              <h5>Product ID:</h5>
              <p>8754788687</p>
            </div>
            <div>
              <h5>Order Number:</h5>
              <p>1209472-8454378898-97287239</p>
            </div>
            <div>
              <h5>Invoice Date:</h5>
              <p>12 Jul 2022</p>
            </div>
            <div>
              <h5>Nature Of Transaction:</h5>
              <p>Inter-State</p>
            </div>
            <div>
              <h5>Order Date:</h5>
              <p>12 Jul 2022</p>
            </div>
            <div>
              <h5>Place Of Supply:</h5>
              <p>DELHI</p>
            </div>
            <div>
              <h5>Nature Of Supply:</h5>
              <p>Floor Plan</p>
            </div>
          </div>
        </div>

        <div className='site-wrapper bill-info'>
          <div>
            <h3>Bill From:</h3>
            <div>
              <h4>Stikcon Pvt. Ltd.</h4>
              <p>8th Floor, Bizcospaces,Infra Futura Building,Seaport Airport Road, Kakkanad, Kochi, Kerala-682021</p>
              <div className='item-data'>
                <div><span>GSTIN Number:</span><p>947HFYD976874578</p></div>
                <div><span>Item Name:  </span><p>XYZ</p></div>
                <div><span>HSN:</span><p>867489329. 5.0% IGST</p></div>
              </div>
            </div>
          </div>
          <div>
            <h3>Bill To/Ship To:</h3>
            <div>
              <h4>JIBIN S JOSEPH</h4>
              <p>Under a big ‘W’ tree in St. Sebastian Garden, Altinho, Panaji, Goa 403001 </p>
            </div>
          </div>
        </div>
        {/* Table */}
        <div style={{ overflowX: "auto" }}>
          <table className='invoice-table'>
            <tr>
              <th></th>
              <th>Qty.</th>
              <th>Gross Amount</th>
              <th>Discount</th>
              <th>Other charges</th>
              <th>Taxable Amount</th>
              <th>IGST</th>
              <th>Total Amount</th>
            </tr>
            {invoiceData.map(i => {
              return (
                <tr key={i.id} className="invoice-info">
                  <td></td>
                  <td>{i.qty}</td>
                  <td>{i.grossAmount}</td>
                  <td>{i.discount}</td>
                  <td>{i.otherCharges}</td>
                  <td>{i.taxableAmount}</td>
                  <td>{i.IGST}</td>
                  <td>{i.totalAmount}</td>
                </tr>
              )
            })}
            <tr>
              <td></td>
              <td>Total</td>
              <td>Rs 490.00</td>
              <td>Rs 54.00</td>
              <td>Rs 0.00</td>
              <td>Rs 415.24</td>
              <td>Rs 20.76</td>
              <td>Rs 436.00</td>
            </tr>
          </table>
        </div>
        <div className='site-wrapper'>
          <div className='invoice-footer'>
            <div className='invoice-sign'>
              <h3>Stikcon Pvt. Ltd.</h3>
              <img src={require("../assets/images/sign.png")} alt='sign' />
              <p>Authorised Signatory</p>
            </div>
            <div className='invoice-qr'>
              <img src={require("../assets/images/or.png")} alt='qr' />
            </div>
          </div>
          <p className='invoice-copyright'>If you have any questions, feel free to call customer care at <b>+91 9211420420</b> or use <b>Contact Us</b> section in our App, log on to <b>www.stikcon.com/contactus.</b></p>
        </div>
      </div>
    </div>
  )
}
export default Invoice