// export const BASE_URL = 'https://stkdesign.aventusinformatics.com'
export const BASE_URL = 'https://design.stikcon.com/'


//Auth
export const CUSTOMER_REGISTER_URL = 'auth/customer/register-customer'
export const CUSTOMER_VERIFY_OTP_URL = 'auth/customer/register-verify-otp-customer'
export const CUSTOMER_LOGIN_URL = 'auth/customer/login-customer'
export const CUSTOMER_LOGIN_VERIFY_OTP_URL = 'auth/customer/login-otp-customer'

//faq
export const LIST_FAQ_URL = 'faq/Faq/Get-faq-listing'

//design
export const LIST_TYPOLOGY_CATEGORIES_URL = 'interior/filter-interior-admin-customer'
export const GET_TYPOLOGY_URL = 'interior/custom-admin-get-all-admin-interior-designs'
export const LIST_PRODUCT_URL = 'product/products/get-products'
export const SAVE_CUSTOMER_DESIGN_URL = 'interior/custom-customer-save-interior-designs'
export const DOWNLOAD_CUSTOMER_DESIGN_URL = 'interior/download-designs-interior'
export const ORDER_PAYMENT_DESIGN_URL = 'order/order/order-payment'
export const LIST_CUSTOMER_DESIGNS_URL = 'interior/custom-customer-get-my-interior-designs'
export const WISHLIST_CUSTOMER_DESIGNS_URL = 'wishlist/wishlist/add-to-wishlist'
export const REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL = 'wishlist/wishlist/remove-from-wishlist'
export const GET_CUSTOMER_WISHLIST_URL = 'wishlist/wishlist/get-wishlist'
export const GET_CUSTOMER_SINGLE_DESIGN_URL = 'interior/custom-customer-get-mydesign-interior-design'

//DESIGN CATEGORY LISTING
export const LIST_CATEGORY_STYLE_URL = 'category/styles/get-all-category-styles'
export const LIST_CATEGORY_FELS_URL = 'category/feels/get-all-category-feels'
export const LIST_CATEGORY_INTERIOR_URL = 'category/interior/get-all-category-interior'
export const LIST_CATEGORY_COLOR_PREFFERENCE_URL = 'category/colour-preference/get-all-category-colour-preference'
export const LIST_CATEGORY_COLOR_URL = 'category/colour/get-all-category-colour'
export const LIST_CATEGORY_MATERIAL_URL = 'category/material/get-all-category-material'
export const LIST_CATEGORY_ROOM_LAYOUT_URL = 'category/room-layout/get-all-category-room-layout'
export const LIST_CATEGORY_FURNITURE_URL = 'category/furniture/get-all-category-furniture'
export const LIST_CATEGORY_ROOM_SHAPE_URL = 'category/room-shape/get-all-category-furniture-room-shape'
export const LIST_CATEGORY_TYPOLOGY_URL = 'interior/filter-interior-customer'


//profile
export const GET_CUSTOMER_PROFILE_URL = 'user/profile/get-profile-details'
export const CREATE_CUSTOMER_ADDRESS_URL = 'user/user-address/create-or-update-user-address'
export const DELETE_CUSTOMER_PROFILE_URL = 'user/profile/destroy-my-account'
export const UPDATE_CUSTOMER_PROFILE_URL = 'user/profile/update-my-account'

//home
export const LIST_ROOM_DESIGNS_URL = 'interior/custom-customer-get-interior-designs-room'
export const LIST_ROOM_DESIGNS_DETAILS_URL = 'interior/custom-admin-get-all-admin-interior-designs'
export const CREATE_CONTACT_US_URL = 'faq/contactus/create-update-contactus'
export const LIST_CATEGORY_ROOM_URL = 'category/rooms/get-all-category-rooms'

//payment
export const GET_PAYMENT_LIST_URL = 'packagescustomer-package-/get-customer-package'
export const GET_INVOICE_DETAILS_URL = 'order/order/get-invoice'

// razorpay
export const ADD_PAYMENT_RAZORPAY = 'order/razorpay/get-razorpay';

//products
export const GET_PRODUCTS_LIST_URL = 'product/products/listing-customer-products'
//package
export const CUSTOMER_PACKAGE = 'packagescustomer-package-/create-or-update-customer-package';
export const GET_PACKAGE_DETAILS = 'packagespackage-/get-package';
export const GET_PACKAGE_AVAILABLE = 'packagescustomer-package-/available-package';

//testimonial
export const GET_TESTIMONIAL_DETAILS = 'our-designersdesigners/get-our-testimonial';

//notification
export const GET_NOTIFICATION_LIST = 'notificationNotification/get-listing-notification/';
export const GET_NOTIFICATION_DELETE = 'notificationNotification/destroy-notification';
export const MARK_ALL_AS_READ_NOTIFICATION_URL = 'notificationNotification/disable-notification/';


// our-designers
export const GET_OUR_DESIGNER_LISTING = 'our-designersdesigners/get-our-designers';


//brochure
export const LIST_BROCHURE_URL = 'faq/brochure/get-brochure-listing'