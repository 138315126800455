import React from 'react'
import { Link } from 'react-router-dom';
import Error_Page from '../../assets/images/stikcon3-1.png';

const ErrorPage = () => {
    return (
        <React.Fragment>
            <section className="page_404">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12  col-lg-12 col-md-12 col-12 p-0">
                            <div className="col-sm-12 col-lg-12 col-md-12 col-12 text-center">
                                <div className="four_zero_four_bg">
                                    <img src={Error_Page} alt="404 Page" className='img-fluid' />
                                </div>
                                <div className="contant_box_404">
                                    <h3 className="whoops">
                                        Whoops !
                                    </h3>
                                    <h3 className="something_went">something went</h3>
                                    <h3 className="h2">wrong</h3>
                                    <p className='cant_find'>Sorry we can't seem to find the page you're looking for....</p>
                                    <Link to="/" className="link_404">Go Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ErrorPage;