import React, { useEffect, useState } from 'react'
import SelectCard from '../../components/select-options/select-card'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listCategoryFeels, listDesignFeel } from '../../Redux/Design/action'
import { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons'

const Feel = (props) => {

  const [data, setData] = useState([]);
  const { selectedStyle, selectedRoom, setActive, setSelectedFeel, selectedFeel, packageData, setShow, setIsPremium } = props;
  const selectionRef = useRef(null);
  const [selections, setSelections] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('on_page','feel')
    listCategoryFeels()((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });

  }, [])
  const selectData = (info) => {
    if (info?.feels_subscription == 1) {
      setSelectedFeel(info)
      localStorage.setItem('selectedFeel',JSON.stringify(info))
    }
    else {
      if (packageData?.package_available) {
        setSelectedFeel(info)
        localStorage.setItem('selectedFeel',JSON.stringify(info))
        setIsPremium(true)
      }
      else {
        setShow(true)
      }
    }

  }
  return (
    <div className='site-wrapper'>
      <SelectHead title="Feel" byNo="3" active='style' setActive={setActive} />
      <div className='cards-four-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedFeel?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            <div className="select-type">
              {info.feels_subscription === "1" ? <span className='free'>Free</span> : <span className='premium'>Premium</span>}
            </div>
            <img src={info.feels_image} alt={info.feels_title} className="select-image" />
            <h3 className="select-title">{info.feels_title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        {/* <div>Your selections: <p>{selectedRoom?.room_title}, {selectedStyle?.style_title}</p></div> */}
        <div onClick={() => setSelections(true)}>Your selections <FontAwesomeIcon className="ms-1" icon={faChevronUp} /></div>
        {selections &&
          <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
            <div className="d-flex flex-row justify-content-end pe-4 pt-2">
              <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
              <div className="selection-badge">
                {selectedRoom?.room_title}
              </div>
              <div className="selection-badge">
                {selectedStyle?.style_title}
              </div>

            </div>
          </div>
        }
        <div><button disabled={false} onClick={() => setActive('interiorStyle')} className='button'>Next</button></div>
      </div>
    </div>
  )
}

export default Feel