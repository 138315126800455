import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

const SelectHead = ({ byNo, title, active,setActive }) => {
  return (
    <div className='select-head fw-bolder'>
      <h3>
        {active && (
        
          <FontAwesomeIcon onClick={()=>setActive(active)} icon={faAngleLeft} className="select-back-arrow" />
        )}
        {title}
      </h3>
      <span>{byNo}/9</span>
    </div>
  )
}

export default SelectHead