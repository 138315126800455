import React, { Fragment } from "react";
import Header from '../components/header/header';
import Footer from '../components/footer';

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header indexHeader="false" />
      <main style={{ marginTop: "80px" }}>
        {children}
      </main>
      <Footer />
    </Fragment>

  )
}

export default Layout