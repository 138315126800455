import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { getNotificationList, markAllAsReadNotification } from "../../Redux/Notification/action";

const Notification = () => {

  const [isOpenNotif, setIsOpenNotif] = useState(false);
  const [notification, setNotification] = useState([])

  const toggleNotifPopup = () => {
    setIsOpenNotif(!isOpenNotif);
  }

  useEffect(() => {
    getNotificationList(4, 0)((response) => {
      if (response && response.status) {
        setNotification(response?.data?.results)
      }
    });
  }, []);
  const onClickmarkAllAsRead = () => {
    markAllAsReadNotification()((response) => {
      if (response && response.status) {
        getNotificationList()((response) => {
          if (response && response.status) {
            setNotification(response?.data?.results)
          }
        });
      }
    });
  }
  let notefRef = useRef()

  useEffect(() => {
    let handler = (event) => {
      if (notefRef && notefRef.current) {
        if (!notefRef.current.contains(event.target)) {
          setIsOpenNotif(false);
        }
      }
    }
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });


  const notifications = notification.map((notification_data, index) => {
    return (
      <>
        <div key={index} className="notification-popup-content">
         
          <div className="popup-content">
            {notification_data?.is_read == true ?
              <h4 className='notification_read'>{notification_data?.title}</h4>
              :
              <h4 className='notification_unread'>{notification_data?.title}</h4>
            }
            <p>{notification_data?.description}</p>
          </div>
        </div>
      </>
    )
  })

  return (
    <div>
      <FontAwesomeIcon icon={faBell}
        onClick={toggleNotifPopup} style={{ cursor: "pointer", fontSize: "20px" }} />
      {isOpenNotif &&
        <div className="notification-popup" ref={notefRef}>
          <div className="notification-popup-info">
            <h5>Notifications</h5>
            <div className="notification_clearall">

              <span className="me-3" onClick={() => onClickmarkAllAsRead()}>Clear all</span>
              <FontAwesomeIcon icon={faXmark} onClick={toggleNotifPopup} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {notifications}
          <div className="notification-view">
            <Link className='' to='/viewallnotification'>
              <h5 className='view-notifi'>View All Notifications</h5>
            </Link>
          </div>

        </div>
      }
    </div>
  )
}

export default Notification