import { DOWNLOAD_CUSTOMER_DESIGN_URL, GET_CUSTOMER_SINGLE_DESIGN_URL, GET_CUSTOMER_WISHLIST_URL, GET_TYPOLOGY_URL, LIST_CATEGORY_COLOR_PREFFERENCE_URL, LIST_CATEGORY_COLOR_URL, LIST_CATEGORY_FELS_URL, LIST_CATEGORY_FURNITURE_URL, LIST_CATEGORY_INTERIOR_URL, LIST_CATEGORY_MATERIAL_URL, LIST_CATEGORY_ROOM_LAYOUT_URL, LIST_CATEGORY_ROOM_SHAPE_URL, LIST_CATEGORY_STYLE_URL, LIST_CATEGORY_TYPOLOGY_URL, LIST_CUSTOMER_DESIGNS_URL, LIST_PRODUCT_URL, LIST_TYPOLOGY_CATEGORIES_URL, ORDER_PAYMENT_DESIGN_URL, REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL, SAVE_CUSTOMER_DESIGN_URL, WISHLIST_CUSTOMER_DESIGNS_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getTypologyCategories = (room_id, style_id, feel_id, interior_id, colour_pre_id, colour_id, material_id, layout_id, furniture_id, shape_id) => onResponse => {
    var BASE_URL = LIST_TYPOLOGY_CATEGORIES_URL + '?page=1';
    if (room_id)
        BASE_URL = BASE_URL + '&room_id=' + room_id
    if (style_id)
        BASE_URL = BASE_URL + '&style_id=' + style_id
    if (feel_id)
        BASE_URL = BASE_URL + '&feel_id=' + feel_id
    if (interior_id)
        BASE_URL = BASE_URL + '&interior_id=' + interior_id
    if (colour_pre_id)
        BASE_URL = BASE_URL + '&colour_pre_id=' + colour_pre_id
    if (colour_id)
        BASE_URL = BASE_URL + '&colour_id=' + colour_id
    if (material_id)
        BASE_URL = BASE_URL + '&material_id=' + material_id
    if (layout_id)
        BASE_URL = BASE_URL + '&layout_id=' + layout_id
    if (furniture_id)
        BASE_URL = BASE_URL + '&furniture_id=' + furniture_id
    if (shape_id)
        BASE_URL = BASE_URL + '&shape_id=' + shape_id
    try {
        axiosInstance.get(BASE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getTypologyData = (id) => onResponse => {

    try {
        axiosInstance.get(GET_TYPOLOGY_URL + '?id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleDesignData = (id) => onResponse => {

    try {
        axiosInstance.get(GET_CUSTOMER_SINGLE_DESIGN_URL + '?mydesigns=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listAllCategoryProducts = (category) => onResponse => {
    try {
        axiosInstance.get(LIST_PRODUCT_URL + '?category_id=' + category)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const saveCustomerDesign = (props) => onResponse => {
    try {
        axiosInstance.post(SAVE_CUSTOMER_DESIGN_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCustomerDesigns = () => onResponse => {
    try {
        axiosInstance.get(LIST_CUSTOMER_DESIGNS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const downloadCustomerDesign = (design_id, free) => onResponse => {
    try {
        axiosInstance.get(DOWNLOAD_CUSTOMER_DESIGN_URL + '?design_id=' + design_id + '&free=' + free)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const orderPaymentDesign = (props) => onResponse => {
    try {
        axiosInstance.post(ORDER_PAYMENT_DESIGN_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const wishListCustomerDesign = (props) => onResponse => {
    try {
        axiosInstance.post(WISHLIST_CUSTOMER_DESIGNS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const removeWishListCustomerDesign = (wishlist_id) => onResponse => {
    try {
        axiosInstance.delete(REMOVE_WISHLIST_CUSTOMER_DESIGNS_URL, { data: { product: wishlist_id } })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getCustomerWishList = (id) => onResponse => {
    try {
        axiosInstance.get(GET_CUSTOMER_WISHLIST_URL + '?interior=' + true + '&user_id=' + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryStyles = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_STYLE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryFeels = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_FELS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryInterior = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_INTERIOR_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryColorPrefferences = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_COLOR_PREFFERENCE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryColor = (feel_id) => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_COLOR_URL + '?colour_feels=' + feel_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryMaterial = (material_styles, material_feels) => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_MATERIAL_URL + '?material_feels=' + material_feels + '&material_styles=' + material_styles)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryRoomLayout = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_ROOM_LAYOUT_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryFurniture = () => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_FURNITURE_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryRoomShape = (selectedRoom_id, selectedStyle_id, selectedFeel_id, selectedInteriorStyle_id, selectedColorPreference_id, selectedColor_id, selectedMaterial_id, selectedRoomLayout_id) => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_ROOM_SHAPE_URL + '?room_shape_layout=' + selectedRoomLayout_id + '&rooms_shape_mc=' + selectedMaterial_id + '&rooms_shape_colour=' + selectedColor_id + '&rooms_shape_cp=' + selectedColorPreference_id + '&room_shape_feel=' + selectedFeel_id + '&room_shape_style=' + selectedStyle_id + '&room_shape_room=' + selectedRoom_id + '&rooms_shape_interior=' + selectedInteriorStyle_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listCategoryTypology = (room_id, style_id, feel_id, interior_id, colour_pre_id, colour_id, material_id, layout_id, furniture_id, shape_id) => onResponse => {
    try {
        axiosInstance.get(LIST_CATEGORY_TYPOLOGY_URL + '?room_id=' + room_id + '&style_id=' + style_id + '&feel_id=' + feel_id + '&interior_id=' + interior_id + '&colour_pre_id=' + colour_pre_id + '&colour_id=' + colour_id + '&material_id=' + material_id + '&layout_id=' + layout_id + '&furniture_ids=' + furniture_id + '&shape_id=' + shape_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};