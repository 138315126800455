import { GET_PRODUCTS_LIST_URL } from "../../api";
import axiosInstance from "../../components/axiosInstance";

export const getProductsListing = (page, limit) => onResponse => {
    try {
        axiosInstance.get(GET_PRODUCTS_LIST_URL + '?page=' + page + '&limit=' + limit)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleProductListing = (id) => onResponse => {
    try {
        axiosInstance.get(GET_PRODUCTS_LIST_URL + '?id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getCategoryProductListing = (id) => onResponse => {
    try {
        axiosInstance.get(GET_PRODUCTS_LIST_URL + '?category_id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};