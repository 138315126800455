import React, { useState, Fragment } from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import Invoice from '../components/invoice';

const PurchasedItems = () => {

  // Order Details
  const [active, setActive] = useState("")

  // Invoice
  const [activeView, setActiveView] = useState("")
  const togglePopup = () => {
    setActiveView(!activeView);
  }

  const PurchasedItemsArr = [
    {
      id: 1, image: require("../assets/images/dd01.png"), title: "Custom Brooklyn Sofa in Heathered Taupe",
      price: "23,000", deliverDate: "Deliverd on 12.08.2022", link: "/your-design"
    },
    {
      id: 2, image: require("../assets/images/f4.png"), title: "Posse Upholstered Fabric Accent Chair",
      price: "23,000", deliverDate: "Deliverd on 12.08.2022", link: "/your-design"
    }
  ]

  const OrderDetails = () => {
    return (
      <div className='order-details'>
        <div className='head'>
          <FontAwesomeIcon icon={faAngleLeft} className="select-back-arrow" onClick={() => setActive(!active)} />
          <h3>Order details</h3>
        </div>
        <div className='details'>
          <p>Ordered on 7.08.2022</p>
          <p>Order# 4400-44558860</p>
        </div>
        <div className='info-section'>
          <div className='info'>
            <div className='image-and-details'>
              <img src={require("../assets/images/dd01.png")} alt={"sofa"} />
              <div>
                <p>Wayfair</p>
                <h3>Custom Brooklyn Sofa in Heathered Taupe</h3>
                <p>₹ 23,000</p>
              </div>
            </div>
            <div className='buttons'>
              <button onClick={() => setActiveView("Invoice")} className="invoice-btn">
                <FontAwesomeIcon icon={faDownload} />Invoice
              </button>
              <button className='button'>Buy it again</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {activeView === "Invoice" && <Invoice handleClick={togglePopup} />}
      <Layout>
        <div className='site-wrapper'>
          {active === "orderDetails" ? (<OrderDetails />) : (
            <div>
              <h3 className='my-designs-title'>Purchased Items</h3>
              <div className='my-designs-cards'>
                {PurchasedItemsArr.map(p => {
                  return (
                    <div className="purchased-card" key={p.id}>
                      <img src={p.image} alt={p.title} />
                      <div className='info'>
                        <h3 onClick={() => setActive("orderDetails")}>{p.title}</h3>
                        <div>
                          <p>₹ {p.price}</p>
                          <span>{p.deliverDate}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </Fragment>
  )
}

export default PurchasedItems