import React, { useEffect, useRef, useState } from 'react'
import SelectCard from '../../components/select-options/select-card'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import SelectHead from '../../components/select-options/select-head'
import { getTypologyCategories, listCategoryTypology } from '../../Redux/Design/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons'

const Typology = (props) => {

  const [data, setData] = useState([]);
  const { setActive, selectedStyle, selectedRoom, selectedFeel, selectedInteriorStyle, selectedColorPreference, selectedColor, selectedMaterial, selectedRoomLayout, selectedFurniture, selectedRoomShape, setSelectedRoomShape, selectedTypology, setSelectedTypology, packageData, setShow } = props;
  const selectionRef = useRef(null);
  const [selections, setSelections] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('on_page','typology')
    var furniture_id = [];
    selectedFurniture?.map(furniture => {
      furniture_id.push(furniture?.id);
    })
    listCategoryTypology(selectedRoom?.id, selectedStyle?.id, selectedFeel?.id, selectedInteriorStyle?.id, selectedColorPreference?.id, selectedColor?.id, selectedMaterial?.id, selectedRoomLayout?.id)((response) => {
      if (response && response.status) {
        setData(response?.data?.results)
      }
    });
    
  }, [])
  const selectData = (info) => {
    setSelectedTypology(info)
    localStorage.setItem('selectedTypology',JSON.stringify(info))
   

  }
  return (
    <div className='site-wrapper'>
      <SelectHead title="Typology" byNo="9" active='room_layout' setActive={setActive} />
      {/* <div className='cards-three-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedTypology?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>

            <img src={info.interior_image} alt={info.room_shape_title} className="select-image" />
            <h3 className="select-title">{info.title}</h3>
          </button>
        )}
      </div> */}
      <div className='cards-four-list'>
        {data?.map((info, index) =>
          <button
            key={index}
            className={selectedTypology?.id == String(info.id) ? "select-card-active" : "select-card"}
            id={info.id}
            onClick={() => selectData(info)}
          >
            <div className="select-type-active">
              <span>✓</span>
            </div>
            
            <img src={info.interior_image} alt={info.title} className="select-image" />
            <h3 className="select-title">{info.title}</h3>
          </button>
        )}
      </div>
      <div className='breadcrumb'>
        {/* <div>Your selections: <p>
          {selectedRoom?.room_title},
          {selectedStyle?.style_title},
          {selectedFeel?.feels_title},
          {selectedInteriorStyle?.interior_title},
          {selectedColorPreference?.colour_preference_title},
          {selectedColor?.colour_title},
          {selectedMaterial?.material_title},
          {selectedRoomLayout?.room_layout_title},
          {selectedFurniture?.map(furniture =>
            furniture?.furniture_title ? furniture?.furniture_title + ',' : ''
          )},
          {selectedRoomShape?.room_shape_title},
        </p></div> */}
        <div onClick={() => setSelections(true)}>Your selections <FontAwesomeIcon className="ms-1" icon={faChevronUp} /></div>
        {selections &&
          <div ref={selectionRef} className={selections ? "selection-drawer active" : "selection-drawer"}>
            <div className="d-flex flex-row justify-content-end pe-4 pt-2">
              <div onClick={() => setSelections(false)}><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
              <div className="selection-badge">
                {selectedRoom?.room_title}
              </div>
              <div className="selection-badge">
                {selectedStyle?.style_title}
              </div>
              <div className="selection-badge">
                {selectedFeel?.feels_title}
              </div>
              <div className="selection-badge">
                {selectedInteriorStyle?.interior_title}
              </div>
              <div className="selection-badge">
                {selectedColorPreference?.colour_preference_title}
              </div>
              <div className="selection-badge">
                {selectedColor?.colour_title}
              </div>
              <div className="selection-badge">
                {selectedMaterial?.material_title}
              </div>
              <div className="selection-badge">
                {selectedRoomLayout?.room_layout_title}
              </div>

            </div>
          </div>
        }
        <div><button disabled={false} onClick={() => setActive('design')} className='button'>Next</button></div>
      </div>
    </div>
  )
}

export default Typology 